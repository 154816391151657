<template>
  <div style="position: relative">
    <img :src="src" :title="title" :width="width" :height="height" @error="(e) => e.target.src = fallback" />
    <div
      :class="{
        ribbon: true,
        'ribbon-orange': ribbon.type === 'warning',
        'ribbon-red': ribbon.type === 'error',
        'ribbon-yellow': ribbon.type === 'yellow',
        'ribbon-green': ribbon.type === 'success',
      }"
      v-for="ribbon in ribbons"
      :key="ribbon.id">
      <el-tooltip effect="dark" :content="ribbon.tooltip" placement="top" :enterable="false">
        <span>{{ribbon.text}}</span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    width: Number,
    height: Number,
    title: String,
    fallback: String,
    ribbons: Array,
  }
}
</script>

<style scoped lang="scss">
.ribbon {
  position: absolute;
  left: -4px; top: -3px;
  z-index: 1;
  overflow: hidden;
  width: 50px; height: 50px;
  text-align: right;

  span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 60px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#6BC7DC 0%, #6BC7DC 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 3px; left: -16px;

    &::before {
      content: "";
      position: absolute; left: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid #6BC7DC;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #6BC7DC;
    }

    &::after {
      content: "";
      position: absolute; right: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #6BC7DC;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #6BC7DC;
    }
  }
}
.ribbon:nth-child(2) {
  left: -4px; top: -2px;
  width: 80px; height: 80px;

  span {
    width: 75px;
    top: 11px;
    line-height: 17px;
  }
}

.ribbon:nth-child(3) {
  left: -6px; top: -4px;
}

.ribbon-orange span {
  background: #79A70A;
  background: linear-gradient(#dc9c6b 0%, #dc9c6b 100%);
}
.ribbon-orange span::before {
  border-left: 3px solid #dc9c6b;
  border-top: 3px solid #dc9c6b;
}
.ribbon-orange span::after {
  border-right: 3px solid #dc9c6b;
  border-top: 3px solid #dc9c6b;
}
.ribbon-red span {
  background: #cb303b;
  background: linear-gradient(#cb303b 0%, #cb303b 100%);
}
.ribbon-red span::before {
  border-left: 3px solid #cb303b;
  border-top: 3px solid #cb303b;
}
.ribbon-red span::after {
  border-right: 3px solid #cb303b;
  border-top: 3px solid #cb303b;
}
.ribbon-yellow span {
  color: #6b7a9b;
  background: #fff04d;
  background: linear-gradient(#fff04d 0%, #fff04d 100%);
}
.ribbon-yellow span::before {
  border-left: 3px solid #fff04d;
  border-top: 3px solid #fff04d;
}
.ribbon-yellow span::after {
  border-right: 3px solid #fff04d;
  border-top: 3px solid #fff04d;
}
.ribbon-green span {
  background: #49da3c;
  background: linear-gradient(#49da3c 0%, #49da3c 100%);
}
.ribbon-green span::before {
  border-left: 3px solid #49da3c;
  border-top: 3px solid #49da3c;
}
.ribbon-green span::after {
  border-right: 3px solid #49da3c;
  border-top: 3px solid #49da3c;
}
</style>