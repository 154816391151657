export default {
  bind(el, binding, vnode) {
    if (el) {
      let store = vnode.context.$store.state;
      var locale, permCheck;
      if (typeof binding.value === 'object') {
        locale = binding.value.locale || store.locale.code;
        if (Array.isArray(binding.value)) {
          permCheck = binding.value;
        } else {
          if (Array.isArray(binding.value.perm)) {
            permCheck = binding.value.perm;
          } else {
            permCheck = [binding.value.perm];
          }
        }
      } else {
        locale = store.locale.code;
        if (Array.isArray(binding.value)) {
          permCheck = binding.value;
        } else {
          permCheck = [binding.value];
        }
      }
      var allowed = false;
      if (store.user) {
        let user = store.user;
        let userPerms = user.permissions;

        permCheck.push('admin'); // Always add admin to perm check as they can always access stuff

        for (let perm of permCheck) {
          if (userPerms.general.includes(perm) || (userPerms[locale.toLowerCase()] || []).includes(perm)) {
            allowed = true;
          }
        }
      }

      if (allowed) {
        el.style.display = '';
      } else {
        el.style.display = 'none';
      }
    }
  }
}
