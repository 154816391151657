<template>
  <el-container class="root">
    <el-main>

      <el-table
        class="app-list"
        empty-text="No apps"
        :data="appData"
        style="width: 100%">

        <el-table-column label="Icon" width="75">
          <template slot-scope="scope">
            <el-image style="width: 56px; height: 56px;" :src="scope.row.icon_url|bigIcon">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="" width="45">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="750"
              trigger="hover"
              show-overflow-tooltip>
              <el-table :data="scope.row.history" :max-height="220">
                <el-table-column min-width="150" prop="app_name" label="Name" />
                <el-table-column min-width="180" prop="package_name" label="Bundle ID" />
                <el-table-column min-width="55" prop="version" label="Version" />
              </el-table>
              <i slot="reference" style="font-size: 20px;" class="el-icon-time" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="Name"
          sortable
          sort-by="app_name"
          width="280">
          <template slot-scope="scope">
            <el-link class="app-name" target="_blank" :href="scope.row.store_link">{{scope.row.app_name}} <i class="el-icon-view el-icon--right"></i></el-link>
          </template>
        </el-table-column>
        <el-table-column label="Category" width="200" prop="category" />
        <el-table-column
          label="Bundle">
          <template slot-scope="scope">
            <div class="app-name">{{scope.row.bundle_id}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="Version">
          <template slot-scope="scope">
            <div>{{latestVersion(scope.row).version}}</div>
          </template>
        </el-table-column>
        <el-table-column
          widdth="120"
          label="Actions">
          <template slot-scope="scope">
            <el-button size="mini" type="success" v-permission="'admin'" @click="approve(scope.row)">Approve and Create</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- Loading indicator -->
      <span class="data-loading-indicator" v-show="appDataLoading" v-loading="appDataLoading"></span>

    </el-main>
  </el-container>
</template>

<script>
import { latestVersion, bundleBase, bundleIdent, setPageTitle } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'Development',
  data () {
    return {
      appData: [],
      appDataLoading: true,
    }
  },
  mounted () {
    if (!this.user) {
      return this.$router.replace('/login');
    }
    if (!this.user.permissions.general.includes('admin') && !this.user.permissions.general.includes('developer')) {
      return this.$router.replace({name: 'Index'});
    }
    setPageTitle('Development');
    this.loadApps();
  },
  methods: {
    latestVersion,

    async loadApps () {
      this.appDataLoading = true;
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/dev/apps');
      this.appData = response.data.apps;
      this.appDataLoading = false;
    },
    approve (app) {
      this.$router.push({name: 'New', params: {
        index: 'base',
        dev: {
          bundleId: app.bundle_id,
          appName: app.app_name.replace(/\s+?\d+\.\d+(\.\d+)?/, ''),
          category: app.category,
          versionCode: latestVersion(app).version_code,
          approve: true
        }
      }});
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      locale: state => state.locale
    }),
  },
  filters: {
    bundleBase,
    bundleIdent,
    bigIcon (icon_url) {
      if (icon_url)
        return icon_url.replace('42x42.', '96x96.');
      else
        return null;
    }
  },
  components: {

  }
}
</script>

<style lang="scss">
.el-table tbody td {
  padding: 7px 0;
}
.el-table .cell {
  word-break: break-word;
}
.el-image .image-slot {
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 8px;

  i {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
</style>
<style scoped lang="scss">
.root {
  margin-top: 25px;

  .el-main {
    position: relative;
  }

  .app-list {
    tbody td {
      padding: 0;
    }

    .app-name {
      i {
        display: none;
      }

      &:hover i {
        display: inline-block;
      }
    }
  }

  .data-loading-indicator {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
  }
}
</style>
