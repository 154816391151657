<template>
  <el-container class="root">
    <el-aside width="250px" style="max-height: 85vh;" v-loading="categories.length === 0">
      <el-menu
        default-active="2"
        class="category-menu">
        <el-menu-item :class="{'is-active': 'all' === category}" @click="handleCategorySelect('all')" index="all">
          <div>
            <span>All</span>
          </div>
        </el-menu-item>
        <el-submenu 
          :index="group.title"
          v-for="group in categories.groups"
          :key="group.title">
          <div
            slot="title"
            :class="{'is-active': group.title === category}"
            :style="'background-color: rgba(' + group.color + ',0.33)'"
            @click.stop="handleCategorySelect(group.title)">
            <span>{{group.title}}</span>
          </div>
          <el-menu-item :class="{'is-active': cat.id === category}"
            @click="handleCategorySelect(cat.id)"
            :index="cat.title"
            v-for="cat in group.categories"
            :key="cat.id">
            <div :style="'background-color: rgba(' + cat.color + ',0.33)'">
              <span>{{cat.title|truncate(25)}}</span>
            </div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item :class="{'is-active': cat.id === category}"
          @click="handleCategorySelect(cat.id)"
          :index="cat.title"
          v-for="cat in visibleCategories"
          :key="cat.id">
          <div :style="'background-color: rgba(' + cat.color + ',0.33)'">
            <span>{{cat.title|truncate(25)}}</span>
          </div>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>

      <div style="height: 85vh; position: relative;">
      <vxe-table
          :data="filteredAppData"
          :loading="appDataLoading"
          highlight-hover-row
          border="inner"
          max-height="100%"
          ref="appTable"
          class="app-list"
          :row-class-name="rowClassName"
          @cell-click="appListRowClick"
          @filter-change="filterHandler">
        <template v-slot:empty><p>No data</p></template>

        <vxe-table-column title="Icon" width="75" fixed="left">
          <template v-slot="{ row }">
            <el-image style="width: 42px; height: 42px;" :src="row.icon_url">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Name" field="app_name"
            sortable :sort-method="sortableAppName"
            :filter-method="() => true" :filters="[
              {label: 'Translated', value: 'nametranslated'},
              {label: 'Not Translated', value: '!nametranslated'},
            ]"
            min-width="280" width="auto">
          <template v-slot="{ row }">
            <div class="app-name">{{row.app_name}}
              <el-tooltip effect="dark" content="Name equal to base" placement="top" :enterable="false" v-if="!appNameTranslated(row)">
                <i class="el-icon-warning-outline color-danger" />
              </el-tooltip>
            </div>
            <div class="base-name">{{row.base.app_name}}</div>
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Bundle" field="bundle_id"
            sortable :sort-method="sortableBundleId"
            min-width="280" width="auto">
          <template v-slot="{ row }">
            <div class="app-name">{{row.bundle_id|bundleIdent}}</div>
            <div class="base-name">{{row.bundle_id|bundleBase}}</div>
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Content" field="translated"
            sortable :sort-method="sortablePropContent"
            :filter-method="() => true" :filters="[
              {label: 'Translated', value: 'translated'},
              {label: 'Translated Some', value: 'translatedsome'},
              {label: 'Not Translated', value: '!translated'},
              {label: 'Buildable', value: 'buildable'},
              {label: 'Not Buildable', value: '!buildable'},
              {label: 'Has Bugs', value: 'hasbugs'},
              {label: 'Has Bugs: Critical', value: 'hasbugs-critical'},
              {label: 'Has Bugs: Important', value: 'hasbugs-important'},
              {label: 'Has Bugs: Aesthetic', value: 'hasbugs-aesthetic'},
              {label: 'Has No Bugs', value: '!hasbugs'},
              {label: 'Has Had Bugs', value: 'hashadbugs'},
            ]"
            min-width="100" width="auto">
          <template v-slot="{ row }">
            <div class="translate-column">
              <el-popover
                  placement="left"
                  width="150"
                  style="display: inline-block;"
                  trigger="hover">
                <el-row :gutter="20" style="margin-top: 5px;" v-if="!!row.translate_status">
                  <el-col :span="24">
                    <b>Translate status</b>
                  </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 5px;" v-if="!!row.translate_status">
                  <el-col :span="15">Is setup</el-col>
                  <el-col :span="8">{{row.translate_status.ready ? 'Yes' : 'No'}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 5px;" v-if="!!row.translate_status">
                  <el-col :span="15">Done</el-col>
                  <el-col :span="8">{{row.translate_status.count}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 5px;" v-if="!!row.translate_status">
                  <el-col :span="15">Total</el-col>
                  <el-col :span="8">{{row.translate_status.total}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 5px;" v-if="!!row.translate_status">
                  <el-col :span="15">Percent done</el-col>
                  <el-col :span="8">{{row.translate_status.percent}}%</el-col>
                </el-row>
                <a slot="reference" target="_blank" :href='"https://translate.leaplearning.no/app/" + row.base_bundle_id'>
                  <icon-8 name="cross" width="32" height="32" color="error" v-show="translateStatus(row) === 'none'" />
                  <icon-8 name="complete-in-progress" width="32" height="32" color="warning" v-show="translateStatus(row) === 'some'" />
                  <icon-8 name="checkmark" width="32" height="32" color="success" v-show="translateStatus(row) === 'all'" />
                </a>
              </el-popover>
              <icon-8 name="bug" width="32" height="32" :color="bugColorForSeverity(worstSeverity(row.open_bugs))" style="margin-left: 7px;" v-show="row.open_bugs.length > 0" />
              <span class="date" v-show="!!row.translate_export_status">
                {{$moment.unix(row.translate_export_status).format('DD.MM.YY')}}
              </span>
            </div>
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Build"
            min-width="60" width="auto"
            :filter-method="() => true" :filters="[
              {label: 'Build: Success', value: 'built'},
              {label: 'Build: Failed', value: 'buildfail'},
              {label: 'Build: Not started', value: 'nobuild'},
              {label: 'Deploy: Success', value: 'deployed'},
              {label: 'Deploy: Failed', value: 'deployfail'},
            ]">
          <template v-slot="{ row }">
            <el-popover
                v-if="(row.build_status && row.build_status !== 'success') ||
                      (row.deploy_status && row.deploy_status !== 'success')"
                placement="left"
                width="750"
                style="display: inline-block;"
                trigger="hover">
              <div slot="reference">
                <icon-8 name="cross" width="32" height="32" color="error" />
              </div>
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 16}"
                placeholder="Trace"
                readonly
                :value="(row.build_trace || '').replace(/(^None|None$)/ig, '') + '\n' + (row.deploy_trace || '').replace(/(^None|None$)/ig, '')" />
            </el-popover>
            <icon-8
                v-else-if="(row.build_status && row.build_status === 'success') ||
                           (row.deploy_status && row.deploy_status === 'success')"
                name="checkmark" width="32" height="32" color="success" />
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Latest"
            :filter-method="() => true" :filters="[
              {label: 'Uni: Tested', value: 'tested'},
              {label: 'Uni: Untested', value: '!tested'},
              {label: 'Uni: Approved', value: 'approved'},
              {label: 'Uni: Denied', value: '!approved'},
              {label: 'Web: Tested', value: 'web_tested'},
              {label: 'Web: Untested', value: '!web_tested'},
              {label: 'Web: Approved', value: 'web_approved'},
              {label: 'Web: Denied', value: '!web_approved'},
              {label: 'Has Bugs', value: 'hasbugs'},
              {label: 'Has No Bugs', value: '!hasbugs'},
              {label: 'Has Had Bugs', value: 'hashadbugs'},
            ]"
            width="160">
          <template v-slot="{ row }">
            <!-- Tablet -->
            <div class="release-column">
              <icon-8 name="tablet-checked" width="28" height="28" :color="null" v-if="!row.latest_version" />
              <template v-else-if="row.latest_version.approved">
                <icon-8 name="tablet-checked" width="28" height="28" color="success" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.approved_at).format('DD.MM.YY')}}</span>
              </template>
              <template v-else-if="!row.latest_version.tested">
                <icon-8 name="tablet-checked" width="28" height="28" color="warning" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.created_at).format('DD.MM.YY')}}</span>
              </template>
              <template v-else-if="!row.latest_version.approved">
                <icon-8 name="tablet-checked" width="28" height="28" color="error" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.tested_at).format('DD.MM.YY')}}</span>
              </template>
            </div>
            <!-- Web -->
            <div class="release-column">
              <icon-8 name="laptop-checked" width="28" height="28" :color="null" v-if="!row.latest_version" />
              <template v-else-if="row.latest_version.web_approved">
                <icon-8 name="laptop-checked" width="28" height="28" color="success" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.web_approved_at).format('DD.MM.YY')}}</span>
              </template>
              <template v-else-if="!row.latest_version.web_tested">
                <icon-8 name="laptop-checked" width="28" height="28" color="warning" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.created_at).format('DD.MM.YY')}}</span>
              </template>
              <template v-else-if="!row.latest_version.web_approved">
                <icon-8 name="laptop-checked" width="28" height="28" color="error" />
                <span>{{row.latest_version.version}}</span>
                <span class="date">{{$moment(row.latest_version.web_tested_at).format('DD.MM.YY')}}</span>
              </template>
            </div>
          </template>
        </vxe-table-column>

        <vxe-table-column
            title="Active"
            :filter-method="() => true" :filters="[
              {label: 'Uni: Uploaded', value: 'published_version'},
              {label: 'Uni: Missing', value: '!published_version'},
              {label: 'Web: Uploaded', value: 'web_published_version'},
              {label: 'Web: Missing', value: '!web_published_version'},
            ]"
            width="160">
          <template v-slot="{ row }">
            <!-- Tablet -->
            <div class="release-column">
              <template v-if="row.published_version">
                <icon-8 name="tablet-cloud" width="28" height="28" color="success" />
                <span>{{row.published_version.version}}</span>
                <span class="date">{{$moment(row.published_version.approved_at).format('DD.MM.YY')}}</span>
              </template>
              <icon-8 v-else name="tablet-cloud" width="28" height="28" color="error" />
            </div>
            <!-- Web -->
            <div class="release-column">
              <template v-if="row.web_published_version">
                <icon-8 name="laptop-cloud" width="28" height="28" color="success" />
                <span>{{row.web_published_version.version}}</span>
                <span class="date">{{$moment(row.web_published_version.approved_at).format('DD.MM.YY')}}</span>
              </template>
              <icon-8 v-else name="laptop-cloud" width="28" height="28" color="error" />
            </div>
          </template>
        </vxe-table-column>

        <vxe-table-column title="Latest bug" width="300" v-if="showBugColumn" v-permission="'developer'">
          <template v-slot="{ row }">
            <p class="bug-column" v-if="row.open_bugs.length > 0">
              {{worstBug(row.open_bugs).text}}
              <template v-if="row.open_bugs.length > 1"><i>+{{row.open_bugs.length-1}} more</i></template>
            </p>
          </template>
        </vxe-table-column>


      </vxe-table>
      </div>

    </el-main>
  </el-container>
</template>

<script>
import { contentComplete, appNameTranslated, bundleBase, bundleIdent, setPageTitle, worstSeverity } from '@/utils'
import Icon8 from '@/components/icons/Icon8.vue'
import { mapState } from 'vuex'

export default {
  name: 'Index',
  data () {
    return {
      category: null,
      appData: [],
      appDataLoading: true,
      hiddenCategories: [],
      showBugColumn: true,
      activeFilters: [],
    }
  },
  mounted () {
    if (!this.user) {
      return this.$router.replace('/login');
    }
    setPageTitle(this.locale.code + ' Index');
    this.loadCategories();
  },
  methods: {
    contentComplete,
    appNameTranslated,
    worstSeverity,

    async loadCategories () {
      this.$store.dispatch('refreshCategories');
      if (this.user.last_category) {
        this.handleCategorySelect(this.user.last_category);
      }

      // Load hidden categories
      try {
        let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/hidden_categories',
                                    {params: {locale: this.locale.code}});
        this.hiddenCategories = response.data.categories || [];
      } catch (e) {
        console.error(e);
      }
    },
    async handleCategorySelect (category) {
      if (this.category === category)
        return
      this.category = category
      this.appDataLoading = true;
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/applist',
                                    {params: {cat: category}});
      this.appData = response.data.apps;
      // this.$refs.appTable.reloadData(this.appData);
      this.appDataLoading = false;
      this.$store.dispatch('setLastCategory', category);
    },
    appListRowClick ({row}) {
      this.$router.push({name: 'AppPage', params: {bundleId: row.bundle_id}})
    },

    rowClassName ({row}) {
      if (row.base && row.base.deprecated) {
        return 'deprecated';
      }
    },
    translateStatus (row) {
      if (!row.translate_status || !row.base.translate_capable || row.translate_status.percent === 0)
        return 'none';
      else if (row.translate_status.percent < 100)
        return 'some';
      else
        return 'all';
    },
    sortablePropContent (a, b) {
      const aCompletion = contentComplete(a, a.base);
      const bCompletion = contentComplete(b, b.base);
      return aCompletion.localeCompare(bCompletion);
    },
    sortableBaseName (a, b) {
      return a.base.app_name.localeCompare(b.base.app_name);
    },
    sortableAppName (a, b) {
      return a.app_name.localeCompare(b.app_name);
    },
    sortableBundleId (a, b) {
      return bundleIdent(a.bundle_id).localeCompare(bundleIdent(b.bundle_id));
    },
    filterHandler ({ values }) {
      this.activeFilters = values;
    },
    filterTest (row, filter) {
      var notNegate = true;
      if (filter.indexOf('!') === 0) {
        notNegate = false;
        filter = filter.substr(1);
      }
      if (filter === "nametranslated") {
        return this.appNameTranslated(row) === notNegate;
      } else if (filter === 'translated') {
        return (this.translateStatus(row) === 'all') === notNegate;
      } else if (filter === 'translatedsome') {
        return this.translateStatus(row) === 'some';
      } else if (filter === "buildable") {
        let version = this.row.latest_version;
        if (!version)
          return !notNegate;
        return (
          (version.translated || row.base.translation_type.includes("No"))
          && (version.recorded || !row.base.audio_requirements)
          && !version.synced
          && this.appNameTranslated(row)) === notNegate;
      } else if (filter === "hasbugs") {
        return (row.open_bugs.length > 0) === notNegate;
      } else if (filter.includes("hasbugs-")) {
        let category = filter.split("-")[1];
        return (row.open_bugs.some(b => b.category === category)) === notNegate;
      } else if (filter === "hashadbugs") {
        for (let version of row.versions) {
          if (version.bugs.length > 0) {
            return notNegate;
          }
        }
        return !notNegate;
      } else if (filter === "published_version") {
        return !!row.published_version === notNegate;
      } else if (filter === "web_published_version") {
        return !!row.web_published_version === notNegate;
      } else if (filter === "built") {
        return row.build_status === "success";
      } else if (filter === "buildfail") {
        return row.build_status === "failed";
      } else if (filter === "nobuild") {
        return row.build_status === undefined || row.build_status === null;
      } else if (filter === "deployed") {
        return row.deploy_status === "success";
      } else if (filter === "deployfail") {
        return row.deploy_status === "failed";
      }

      return (!!row[filter] || (row.latest_version && !!row.latest_version[filter])) === notNegate;
    },
    onSearch (result) {
      this.appDataLoading = true;
      this.category = null;
      this.appData = result;
      this.appDataLoading = false;
    },
    bugColorForSeverity(severity) {
      if (severity === 'other') {
        return '#6BC7DC';
      } else if (severity === 'critical') {
        return 'error';
      } else if (severity === 'important') {
        return 'warning';
      } else if (severity === 'aesthetic') {
        return '#f9ef02';
      } else {
        return '';
      }
    },
    worstBug(bugs) {
      let worstSev = this.worstSeverity(bugs);
      return bugs.find(b => b.category === worstSev || (worstSev === 'other' && !b.category));
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      locale: state => state.locale,
      categories: state => state.categories
    }),
    visibleCategories () {
      return this.categories.categories.filter(c =>
        !this.hiddenCategories.some(hC => hC.category === c.title) ||
        !this.hiddenCategories.find(hC => hC.category === c.title && (
          (hC.roles.includes("Testers") && this.user.permissions[this.locale.code.toLowerCase()] && this.user.permissions[this.locale.code.toLowerCase()].includes("testing")) ||
          (hC.roles.includes("Translaters") && this.user.permissions[this.locale.code.toLowerCase()] && this.user.permissions[this.locale.code.toLowerCase()].includes("translate")) ||
          (hC.roles.includes("Editors") && this.user.permissions[this.locale.code.toLowerCase()] && this.user.permissions[this.locale.code.toLowerCase()].includes("edit")) ||
          (hC.roles.includes("Developer") && this.user.permissions.general.includes("developer"))
        ))
      );
    },
    filteredAppData () {
      if (this.activeFilters.length === 0)
        return this.appData;

      return this.appData.filter(app => this.activeFilters.every(filter => this.filterTest(app, filter)));
    }
  },
  watch: {
    'locale' () {
      this.appData = [];
      this.category = null;
      this.loadCategories();

      if (this.$route.name === 'Index') {
        this.$router.push({ name: 'Index', query: { l: this.locale.code.toLowerCase() }});
      }
    },
    '$route' ({name}) {
      if (name === 'Index') {
        setPageTitle(this.locale.code + ' Index');
      }
    }
  },
  filters: {
    bundleBase,
    bundleIdent
  },
  components: {
    Icon8
  }
}
</script>

<style lang="scss">
.el-table tbody td {
  padding: 7px 0;
}
.el-table .cell {
  word-break: break-word;
}
.el-image .image-slot {
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 8px;

  i {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
.category-menu .el-submenu__icon-arrow {
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: -20px;
  margin-top: -20px;
}
.vxe-header--column:not(.col--ellipsis) {
  padding: 5px 0;
}

.vxe-table--filter-wrapper {
  min-width: 200px !important;
}

.vxe-body--row {
  height: 80px;

  .vxe-cell {
    max-height: 80px !important;
  }
}
</style>
<style scoped lang="scss">
@import "../assets/style-variables.scss";

.root {
  margin-top: 25px;

  > .el-aside {
    margin-top: 35px;

    .el-menu-item {
      height: 40px;
      line-height: 40px;

      &.is-active {
        color: white;
      }
    }
  }

  .el-main {
    position: relative;
  }

  .category-menu {
    .el-menu-item {
      padding: 0 !important;

      > div {
        padding-left: 20px;
        margin-right: 20px;
      }
      &.is-active {
        background-color: $--color-primary !important;

        > div {
          background-color: transparent !important;
        }
      }
    }
    .el-submenu__title {
      padding: 0 !important;

      > div {
        padding-left: 20px;
        margin-right: -20px;
        margin-left: -20px;

        &.is-active {
          background-color: $--color-primary !important;

          span {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .app-list {
    tbody td {
      padding: 0;
    }

    .app-name {
      font-size: larger;
    }
    .base-name {
      font-size: smaller;
    }
    .column-center {
      text-align: center;
    }
    .release-column {
      line-height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span.date {
        font-size: small;
        opacity: 0.75;
      }
    }
    .translate-column span.date {
      position: absolute;
      bottom: 4px;
      left: 4px;
      font-size: small;
      opacity: 0.75;
    }
    .bug-column {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .deprecated {
      * {
        opacity: 0.5;
      }

      div {
        text-decoration: line-through;
      }
    }
    // Fix popover scoped content from being fullwidth
    td .cell > span:first-child {
      display: inline-block;
    }
  }

  .data-loading-indicator {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
  }
}
</style>
