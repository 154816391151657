var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('img',{attrs:{"src":_vm.src,"title":_vm.title,"width":_vm.width,"height":_vm.height},on:{"error":(e) => e.target.src = _vm.fallback}}),_vm._l((_vm.ribbons),function(ribbon){return _c('div',{key:ribbon.id,class:{
      ribbon: true,
      'ribbon-orange': ribbon.type === 'warning',
      'ribbon-red': ribbon.type === 'error',
      'ribbon-yellow': ribbon.type === 'yellow',
      'ribbon-green': ribbon.type === 'success',
    }},[_c('el-tooltip',{attrs:{"effect":"dark","content":ribbon.tooltip,"placement":"top","enterable":false}},[_c('span',[_vm._v(_vm._s(ribbon.text))])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }