<template>
  <el-container class="version-container" v-if="version.loaded" direction="vertical">
    <el-row class="attr-row" :gutter="20">
      <el-col :span="6">
        <el-row class="attr-row" v-show="translationRequired">
          <div>
            <icon-8 name="translate" width="24" height="24" :color="version.data.translated ? 'success' : 'error'" />
            <h3>Translated</h3>
            <audit-history-popup v-permission="'admin'" direction="right" type="version" :id="version.data.id" field="translated" />
          </div>
          <version-attr v-loading="attrSaving === 'translated'" v-model="version.data.translated" @change="saveAttr('translated', $event)" :disabled="disabled" />
        </el-row>
        <el-row class="attr-row" v-show="!!app.base.audio_requirements">
          <div>
            <icon-8 name="microphone" width="24" height="24" :color="version.data.recorded ? 'success' : 'error'" />
            <h3>Recorded</h3>
            <audit-history-popup v-permission="'admin'" direction="right" type="version" :id="version.data.id" field="recorded" />
          </div>
          <version-attr v-loading="attrSaving === 'recorded'" v-model="version.data.recorded" @change="saveAttr('recorded', $event)" :disabled="disabled || !app.base.audio_requirements" />
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row class="attr-row" :gutter="20">
          <el-col :span="24">
            <div>
              <icon-8 name="tablet-checked" width="24" height="24" :color="approved === null ? null : approved ? 'success' : 'error'" />
              <h3>Universal testing</h3>
              <audit-history-popup v-permission="'admin'" direction="right" type="version" :id="version.data.id" field="approved" />
            </div>
            <version-attr
                tristate
                v-loading="attrSaving === 'approved'"
                v-model="approved"
                @change="saveAttr('approved', $event)"
                :labels="['Approved', 'Not tested', 'Rejected']"
                :disabled="disabled" />
          </el-col>
        </el-row>
        <el-row class="attr-row" :gutter="20" style="margin-top: 40px;">
          <el-col :span="24">
            <div>
              <icon-8 name="laptop-checked" width="24" height="24" :color="web_approved === null ? null : web_approved ? 'success' : 'error'" />
              <h3>Web testing</h3>
              <audit-history-popup v-permission="'admin'" direction="right" type="version" :id="version.data.id" field="web_approved" />
            </div>
            <version-attr
                tristate
                v-loading="attrSaving === 'web_approved'"
                v-model="web_approved"
                @change="saveAttr('web_approved', $event)"
                :labels="['Approved', 'Not tested', 'Rejected']"
                :disabled="disabled" />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="9" :offset="1">
        <version-bugs ref="versionBugView" :bugs="version.data.bugs" @save="saveBug" @new="newBug" />
      </el-col>
    </el-row>

  </el-container>
</template>

<script>
import Icon8 from '@/components/icons/Icon8.vue'
import VersionAttr from '@/components/VersionAttr.vue'
import VersionBugs from '@/components/VersionBugs.vue'
import AuditHistoryPopup from '@/components/AuditHistoryPopup.vue'

export default {
  async mounted () {
    await this.load();
  },
  data () {
    return {
      version: {
        loaded: false,
        data: {}
      },
      approved: null,
      web_approved: null,
      attrSaving: null
    }
  },
  computed: {
    translationRequired () {
      return !!this.app.base.translation_type && !this.app.base.translation_type.includes('No');
    }
  },
  props: {
    versionId: Number,
    app: Object,
    disabled: Boolean,
    refreshTrigger: Number,
  },
  methods: {
    async load() {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/version/' + this.versionId);
      if (response.status !== 200) {
          this.$notify.error(response.data.message || 'Oops. Unknown error occured', {duration: 5000});
      } else {
        this.version.data = response.data.version;
        this.approved = !this.version.data.tested ? null : !this.version.data.approved ? false : true;
        this.web_approved = !this.version.data.web_tested ? null : !this.version.data.web_approved ? false : true;
        this.version.loaded = true;
      }
    },
    async saveAttr (attr, { value, previous }) {
      this.attrSaving = attr;
      try {
        if (attr === 'approved') {
          this.version.data.tested = value != null;
          this.version.data.approved = !!value;
        }
        if (attr === 'web_approved') {
          this.version.data.web_tested = value != null;
          this.version.data.web_approved = !!value;
        }
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/version', this.version.data);
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message);
        this.$message.success("Updated.");
      } catch (e) {
        if (attr === 'approved') {
          this.approved = previous;
          this.version.data.tested = previous != null;
          this.version.data.approved = !!previous;
        } else if (attr === 'web_approved') {
          this.web_approved = previous;
          this.version.data.web_tested = previous != null;
          this.version.data.web_approved = !!previous;
        } else {
          this.version.data[attr] = previous;
        }
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.attrSaving = null;
    },
    async saveBug (bug) {
      try {
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/bug',
                                        {bug_id: bug.id, status: bug.status, text: bug.text});
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message)
        this.$message.success("Updated.");
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    },
    async newBug ({text, category}) {
      try {
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/bug',
                                        {version_id: this.version.data.id, bug: text, category: category});
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message)
        this.$message.success("Updated.");
        this.version.data.bugs.push(response.data.bug);
        this.$refs.versionBugView.clearNew();
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    }
  },
  watch: {
    refreshTrigger () {
      this.load();
    }
  },
  components: {
    Icon8,
    VersionAttr,
    VersionBugs,
    AuditHistoryPopup
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style-variables.scss";

.version-container {
  margin-left: 35px;
  margin-right: 35px;

  .attr-row:not(:first-child) {
    margin-top: 25px;
  }

  h3 {
    display: inline-block;
    margin: 10px;
  }

  .version-attr {
    float: left;
  }
}
</style>