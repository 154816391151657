
export function rgba2hex(orig) {
  var a,
    rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = (rgb && rgb[4] || "").trim(),
    hex = rgb ?
    (rgb[1] | 1 << 8).toString(16).slice(1) +
    (rgb[2] | 1 << 8).toString(16).slice(1) +
    (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

  if (alpha !== "") {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = ((a * 255) | 1 << 8).toString(16).slice(1)
  hex = hex + a;

  return hex;
}

export function setPageTitle(subtitle) {
  document.title = `Apptrack | ${subtitle}`;
}

export function bundleSplit(bundleId) {
  const packageRegex = /(com|no)\.(thealphabetking|alphabetking|bokstavkongen|leaplearning)\.(\w+)\.(\w+)([.\w-]+)?/
  const htmmRegex = /(org)\.(africastartup)\.(\w+)([.\w-]+)?/
  var parts = bundleId.match(packageRegex)
  if (!parts) {
    // Try htmm pkgs
    parts = bundleId.match(htmmRegex)
    if (!parts)
      return bundleId
  }
  parts.shift() // Remove first element which is full match
  var base = parts.slice(0, 3).join(".")
  var ident = ""
  if (parts.length === 4) {
    base = parts.slice(0, 2).join(".")
    ident = parts[2] + (parts[3] || '')
  } else if (parts.length >= 5 && parts[4] !== undefined) {
    ident = parts[3] + (parts[4] || '')
  } else {
    ident = parts[3]
  }

  return [base, ident]
}

export function bundleIdent(bundleId) {
  let [, ident] = bundleSplit(bundleId)
  return ident
}

export function bundleBase(bundleId) {
  let [base,] = bundleSplit(bundleId)
  return base
}

export function latestVersion(app) {
  if (!app.versions || app.versions.length === 0)
    return {}

  let versions = app.versions.concat().sort((a, b) => {
    return b.version_code - a.version_code // Highest versionCode first
  })

  return versions[0];
}

export function incrementVersion(version) {
  if (typeof version == 'number')
    return version + 1
  if (!version)
    return null

  let parts = version.match(/(\d+)(\.\d+)(\.\d+)?/)
  if (!parts)
    return version

  return parts.slice(1, -1).join("") + '.' + (parseInt(parts.pop().substr(1)) + 1)
}

export function incrementVersionCode(version, type) {
  if (type === 'patch')
    return version + 1
  if (type === 'minor') {
    if (version / 1000000 >= 1)
      return version + (1000 - version%1000)
    if (version / 100000 >= 1)
      return version + (100 - version%100)
    if (version / 10000 >= 1)
      return version + (100 - version%100)
    if (version / 100 >= 1)
      return version + (10 - version%10)
    return version
  }
  if (type === 'major') {
    if (version / 1000000 >= 1)
      return version + (1000000 - version%1000000)
    if (version / 100000 >= 1)
      return version + (100000 - version%100000)
    if (version / 10000 >= 1)
      return version + (10000 - version%10000)
    if (version / 100 >= 1)
      return version + (100 - version%100)
    return version
  }
  return version
}

export function versionCodeToString(version) {
  let string = version.toString()
  let parts = string.split("")
  if (parts.length === 1)
    return `1.0.${parts[0]-1}`
  if (parts.length === 2)
    return `1.0.${parseInt(parts.join(""))-1}`
  if (parts.length === 3)
    return parts.join(".")
  if (parts.length === 5)
    return `${parts[0]}.${parseInt(parts.slice(1, 3).join(""))}.${parseInt(parts.slice(3, 5).join(""))}`
  if (parts.length === 6)
    return `${parseInt(parts.slice(0, 2).join(""))}.${parseInt(parts.slice(2, 4).join(""))}.${parseInt(parts.slice(4, 6).join(""))}`
  if (parts.length === 7)
    return `${parseInt(parts[0])}.${parseInt(parts.slice(1, 4).join(""))}.${parseInt(parts.slice(4, 7).join(""))}`
}

export function appForLocale(base, locale) {
  let app = base.children.find(a => a.locale === locale.code);
  return app || {};
}

export function contentComplete(app, base) {
  if (!(app.base || base).translation_type || (app.base || base).translation_type.includes("No"))
    return "all";
  let version = latestVersion(app);
  if (version.translated === undefined)
    return "none";
  let fields = [version.translated]
  if ((app.base || base).audio_requirements)
    fields.push(version.recorded);
  if (fields.every(v => !!v))
    return "all";
  if (fields.some(v => !!v))
    return "some";
  return "none";
}

export function appNameTranslated(app) {
  return app.app_name.toLowerCase() !== app.base.app_name.toLowerCase() || app.locale === 'EN' || app.allow_base_name;
}

export function objectEmpty(obj) {
  for (let key in obj)
    return false;
  return true;
}

export function worstSeverity(bugs) {
  let severities = {
    other: 1,
    aesthetic: 2,
    important: 3,
    critical: 4
  }
  var worst = 1;
  for (let bug of bugs) {
    if (severities[bug.category] > worst)
      worst = severities[bug.category];
  }
  return Object.keys(severities)[Object.values(severities).indexOf(worst)];
}
