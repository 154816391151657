<template>
  <el-container class="root">
    <el-aside width="250px"><div style="max-height: calc(100vh - 125px);">
      <el-menu
        default-active="1"
        class="category-menu">
        <el-submenu 
          :index="group.title"
          v-for="group in categories.groups"
          :key="group.title">
          <div
            slot="title"
            :class="{'is-active': group.title === category}"
            :style="'background-color: rgba(' + group.color + ',0.33)'"
            @click.stop="handleCategorySelect(group.title, false)">
            <span>{{group.title}}</span>
          </div>
          <el-menu-item :class="{'is-active': cat.id === category}"
            @click="handleCategorySelect(cat.id, false)"
            :index="cat.title"
            v-for="cat in group.categories"
            :key="cat.id">
            <div :style="'background-color: rgba(' + cat.color + ',0.33)'">
              <span>{{cat.title|truncate(25)}}</span>
              <i @click.stop="editingCategory = cat" class="category-edit el-icon-edit" />
            </div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item :class="{'is-active': cat.id === category}"
          @click="handleCategorySelect(cat.id, false)"
          :index="cat.title"
          v-for="cat in categories.categories"
          :key="cat.id">
          <div :style="'background-color: rgba(' + cat.color + ',0.33)'">
            <span>{{cat.title|truncate(25)}}</span>
            <i @click.stop="editingCategory = cat" class="category-edit el-icon-edit" />
          </div>
        </el-menu-item>
      </el-menu>
    </div></el-aside>

    <edit-category :category="editingCategory" @close="editingCategory = null" />

    <el-main>
      <!-- Loading indicator -->
      <span class="data-loading-indicator" v-loading="appDataLoading" />

      <bases-table :appData="appData" v-if="mode === 'table'" />
      <bases-icon-grid
        :appData="appData"
        :categoryData="categoryData"
        v-if="mode === 'icons' && category !== null && categoryData !== null" />

      <el-radio-group v-if="!appDataLoading && categoryData" v-model="mode" size="medium" class="mode-toggle">
        <el-radio-button label="table">List</el-radio-button>
        <el-radio-button label="icons">Icons</el-radio-button>
      </el-radio-group>
    </el-main>
  </el-container>
</template>

<script>
import { setPageTitle } from '@/utils'
import EditCategory from '@/components/EditCategory.vue'
import BasesTable from '@/pages/bases/Table.vue'
import BasesIconGrid from '@/pages/bases/IconGrid.vue'
import { mapState } from 'vuex'

export default {
  name: 'Index',
  data () {
    return {
      category: null,
      appData: [],
      categoryData: null,
      appDataLoading: true,
      mode: 'table',

      editingCategory: null
    }
  },
  mounted () {
    if (!this.user) {
      return this.$router.replace('/login');
    }
    if (this.$route.name === 'Overview') {
      this.loadCategories();
      setPageTitle("Bases");
    }
  },
  methods: {

    loadCategories () {
      this.$store.dispatch('refreshCategories');
      if (this.appData.length === 0 || this.category != null) {
        this.handleCategorySelect(this.user.last_category, true);
      }
    },
    async handleCategorySelect (category, force) {
      if (this.category === category && !force)
        return
      this.category = category;
      this.appDataLoading = true;
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/baselist',
                                    {params: {cat: category}});
      this.appData = response.data.bases;
      this.categoryData = response.data.base_category;
      if (!response.data.base_category) {
        this.mode = 'table';
      }
      this.appDataLoading = false;
      this.$store.dispatch('setLastCategory', category);
    },
    onSearch (result) {
      this.appDataLoading = true;
      this.category = null;
      this.appData = result;
      this.appDataLoading = false;
    },
  },
  computed: {
    ...mapState({
      user: state => state.user,
      categories: state => state.categories
    }),
  },
  watch: {
    '$route' ({name}) {
      if (name === 'Overview') {
        this.loadCategories();
        setPageTitle("Bases");
      }
    }
  },
  components: {
    EditCategory,
    BasesTable,
    BasesIconGrid,
  }
}
</script>

<style lang="scss">
.el-table tbody td {
  padding: 7px 0;
}
.el-table .cell {
  word-break: break-word;
}
.category-menu .el-submenu__title > i {
  font-size: 16px;
  font-weight: bold;
}
.category-menu .el-submenu__title > .is-active,
.category-menu .el-submenu__title > .is-active + i {
  color: white;
}
</style>
<style scoped lang="scss">
@import "../assets/style-variables.scss";

.root {
  margin-top: 25px;

  > .el-aside {
    margin-top: 35px;

    .el-menu-item {
      height: 40px;
      line-height: 40px;

      &.is-active {
        color: white;
      }

      .category-edit {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 18px;
      }
    }
  }

  .el-main {
    position: relative;
  }

  .category-menu {
    .el-menu-item {
      padding: 0 !important;

      > div {
        padding-left: 20px;
        margin-right: 20px;
      }
      &.is-active {
        background-color: $--color-primary !important;

        > div {
          background-color: transparent !important;
        }
      }
    }
    .el-submenu__title {
      padding: 0 !important;

      > div {
        padding-left: 20px;
        margin-right: -20px;
        margin-left: -20px;

        &.is-active {
          background-color: $--color-primary !important;

          span {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .mode-toggle {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  .data-loading-indicator {
    display: block;
    position: absolute;
    top: 8px;
    left: 50%;
    width: 35px;
    height: 35px;
    transform: translateX(-50%);
  }
}
</style>
