<template>
<el-dialog @close="$emit('close')" v-if="!!category" :visible="!!category">
  <template slot="title">
    <div style="width: 80%; display: flex; gap: 5px; align-items: center;">
      <el-input
        v-if="!!baseCategory"
        v-model="baseCategory.title"
        @blur="editBaseCategoryTitle"
      />
      <span v-else>{{category.title}}</span>
      <el-checkbox v-if="!!baseCategory" v-model="editAllEqual" label="Edit all equal" />
    </div>
  </template>

  <!-- <div>If locale is hidden, it's for:
    <el-select v-model="roles" @change="change" multiple placeholder="Select roles">
      <el-option
        v-for="item in ['Testers', 'Translaters', 'Editors', 'Developers']"
        :key="item"
        :label="item"
        :value="item">
      </el-option>
    </el-select>
  </div> -->

  <el-table stripe :data="localeCategories" :show-header="false">
    <el-table-column prop="name" label="Name" width="180">
      <template slot-scope="scope">
        <country-flag :country="scope.row.flag|lowercase" size="normal" />
        <span style="margin-left: 5px;">{{scope.row.name}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="category" label="Title">
      <template slot-scope="scope">
        <el-input v-model="scope.row.category.title" @blur="editCategoryTitle(scope.row.category)" />
      </template>
    </el-table-column>
    <!-- <el-table-column prop="hidden" label="Hidden" width="100">
      <template slot-scope="scope">
        <el-checkbox :value="scope.row.hidden" label="Hide" :disabled="roles.length === 0" />
      </template>
    </el-table-column> -->
  </el-table>
</el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'

export default {
  data () {
    return {
      originalBaseCategoryTitle: null,
      baseCategory: null,
      roles: [],
      hidden: [],

      editAllEqual: true,
      checkAll: false,
      isIndeterminate: false,
    }
  },
  methods: {
    async getBase() {
      try {
        const resp = await this.$http.get(process.env.VUE_APP_ROOT_API + `/x/base_cat/${this.category.id}`);
        this.baseCategory = resp.data.base_category;
        this.originalBaseCategoryTitle = this.baseCategory.title;
      } catch (e) {
        console.error(e);
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async editCategoryTitle(cat) {
      try {
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/cat', {
          baseCatId: this.baseCategory.id,
          locale: cat.locale,
          title: cat.title
        });
        this.$message({type: 'success', message: 'Updated.'});
        const baseCatCat = this.baseCategory.categories.find(c => c.locale === cat.locale);
        if (!baseCatCat) {
          this.baseCategory.categories.push(cat);
        } else {
          baseCatCat.title = cat.title;
        }
      } catch (e) {
        console.error(e);
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async editBaseCategoryTitle() {
      try {
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/base_cat', {
          baseCatId: this.baseCategory.id,
          title: this.baseCategory.title
        });
        this.$message({type: 'success', message: 'Updated.'});
        if (this.editAllEqual) {
          this.baseCategory.categories.forEach(async c => {
            if (c.title === this.originalBaseCategoryTitle) {
              c.title = this.baseCategory.title;
            }
            await this.editCategoryTitle(c);
          });
        }
        this.originalBaseCategoryTitle = this.baseCategory.title;
      } catch (e) {
        console.error(e);
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async change () {
      this.checkAll = this.hidden.length === this.locales.length;
      this.isIndeterminate = this.hidden.length > 0 && this.hidden.length < this.locales.length;

      try {
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/hidden_cat', {
          cat: this.category.name,
          locales: this.hidden,
          roles: this.roles
        });
        this.$message({type: 'success', message: 'Updated.'});
      } catch (e) {
        console.error(e);
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    handleCheckAll (val) {
      this.hidden = val ? this.locales.map(l => l.code) : [];
      this.change();
    }
  },
  computed: {
    ...mapState({
      locales: state => state.locales
    }),
    localeCategories() {
      if (!this.baseCategory) return [];
      return this.locales.map(l => {
        const cat = this.baseCategory.categories.find(c => c.locale === l.code);
        return {
          ...l,
          category: cat || {title: this.baseCategory.title, locale: l.code},
          hidden: cat && !!this.hidden.find(h => h === l.code)
        }
      });
    }
  },
  props: {
    category: Object
  },
  watch: {
    async category (category) {
      if (category == null) {
        this.baseCategory = null;
        this.roles = [];
        this.hidden = [];
        this.isIndeterminate = false;
        this.checkAll = false;
        return;
      }

      this.getBase();

      // let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/hidden_categories',
      //                               {params: {cat: category.name}});
      // if (response.data.categories.length > 0) {
      //   this.roles = response.data.categories[0]["roles"] === "" ? [] : response.data.categories[0]["roles"].split(",");
      //   this.hidden = response.data.categories.map(c => c.locale);
      //   this.checkAll = this.hidden.length === this.locales.length;
      //   this.isIndeterminate = this.hidden.length > 0 && this.hidden.length < this.locales.length;
      // }
    }
  },
  components: {
    CountryFlag
  }
}
</script>


<style lang="scss">
.edit-category-checkbox .el-checkbox__label {
  display: flex;
  align-items: center;
}
</style>