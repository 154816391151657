<template>
  <el-container direction="vertical" v-loading="loading" style="margin-right: 20px;">
    <el-header v-if="base.loaded">
      <el-row type="flex" :gutter="20">
        <el-col :span="2">
          <el-image style="width: 75px; height: 75px;" :src="bigIcon">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <h2>{{base.data.app_name}}</h2>
          <h4>{{base.data.base_categories.map(assoc => assoc.base_category.title).join(", ")}}</h4>
        </el-col>
        <el-col :span="3">
          <el-tooltip effect="dark" content="Leap Commit Lock" :enterable="false" placement="left">
            <div v-if="base.data.dev_locked" class="icon-locked el-icon-lock color-danger"></div>
            <div v-else class="icon-locked el-icon-unlock color-success"></div>
          </el-tooltip>
          <el-tooltip v-permission="'admin'" effect="dark" content="Deprecate" v-show="!base.data.deprecated" :enterable="false" placement="left">
            <div @click="deprecate" class="icon-delete el-icon-delete color-danger"></div>
          </el-tooltip>
          <el-tooltip v-permission="['admin', 'base_edit']" effect="dark" content="UnDeprecate" v-show="base.data.deprecated" :enterable="false" placement="left">
            <div @click="undeprecate" class="icon-delete el-icon-circle-plus color-success"></div>
          </el-tooltip>
          <el-tooltip v-permission="['admin', 'base_edit']" effect="dark" content="Create missing languages" v-show="!base.data.deprecated" :enterable="false" placement="left">
            <div @click="createMissingLocales" class="icon-add-location el-icon-add-location color-success"></div>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-header>

    <el-main v-if="base.loaded">
      <el-container direction="vertical">
        <el-row :gutter="16" type="flex" align="middle">
          <el-col :span="1"><icon-8 name="tag" width="24" height="24" /></el-col>
          <el-col :span="3"><span>Bundle ID</span></el-col>
          <el-col :span="18"><span>{{base.data.bundle_id}}</span></el-col>
        </el-row>
        <el-row :gutter="16" type="flex" align="middle">
          <el-col :span="1"><icon-8 name="flag2" width="18" height="18" /></el-col>
          <el-col :span="3"><span>Flag position</span></el-col>
          <el-col :span="17">
            <el-select v-model="base.data.icon_flag_pos" @change="saveAttrs" size="mini" placeholder="Flag position">
              <el-option :value="null" label="Not set" />
              <el-option value="left" label="Left" />
              <el-option value="right" label="Right" />
              <el-option value="false" label="None" />
            </el-select>
          </el-col>
        </el-row>

        <el-divider content-position="left">Attributes</el-divider>

        <el-row class="attr-row" style="margin-top: 20px;" :gutter="16" type="flex" align="middle">
          <el-col :span="12">
            <el-col :span="2"><icon-8 name="translate" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Translation</span></el-col>
            <el-col :span="18">
              <el-switch
                v-model="translationRequired"
                @change="saveBool('translation_type', $event)"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-col>
          </el-col>

          <el-col :span="12">
            <el-col :span="2"><icon-8 name="microphone" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Recording</span></el-col>
            <el-col :span="18">
              <el-switch
                v-model="recordingRequired"
                :disabled="!translationRequired"
                @change="saveBool('audio_requirements', $event)"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-col>
          </el-col>
        </el-row>

        <el-row class="attr-row" :gutter="16" type="flex" align="middle">
          <el-col :span="12">
            <el-col :span="2"><icon-8 name="star" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Prioritize</span></el-col>
            <el-col :span="18">
              <el-switch
                v-model="base.data.prioritize"
                @change="saveBool('prioritize', $event)"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-col>
          </el-col>

          <el-col :span="12">
            <el-col :span="2"><icon-8 name="autobuild" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Autobuildable</span></el-col>
            <el-col :span="18">
              <el-switch
                v-model="base.data.autobuild_capable"
                @change="saveBool('autobuild_capable', $event)"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-col>
          </el-col>
        </el-row>

        <el-row class="attr-row" :gutter="16" type="flex" align="middle">
          <el-col :span="12"></el-col>
          <el-col :span="12">
            <el-col :span="2"><icon-8 name="translate" width="24" height="24" /></el-col>
            <el-col :span="4"><span>TT Export ready</span></el-col>
            <el-col :span="18">
              <el-switch
                v-model="base.data.translate_capable"
                @change="saveBool('translate_capable', $event)"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-col>
          </el-col>
        </el-row>

        <el-row class="attr-row" :gutter="16" type="flex" align="middle">
          <el-col :span="12">
            <el-col :span="2"><icon-8 name="code_fork" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Git URL</span></el-col>
            <el-col :span="18" class="attr-edit">
              <template v-if="gitUrlEditing === null && !base.data.git_url">
                <el-link icon="el-icon-plus" @click="createGitRepo">Autocreate</el-link>
              </template>
              <template v-else-if="gitUrlEditing === null">{{base.data.git_url}}</template>
              <el-input
                v-else
                v-model="gitUrlEditing"
                size="mini" />
              <el-link class="attr-edit-link" icon="el-icon-edit" v-if="gitUrlEditing === null" @click="gitUrlEditing = base.data.git_url || ''" v-permission="['base_edit', 'developer']">Edit</el-link>
              <el-link class="bug-save" icon="el-icon-check" v-else @click="saveGitUrl">Save</el-link>
            </el-col>
          </el-col>

          <el-col :span="12">
            <el-col :span="2"><icon-8 name="server" width="24" height="24" /></el-col>
            <el-col :span="4"><span>Code Path</span></el-col>
            <el-col :span="18" class="attr-edit">{{base.data.code_path}}</el-col>
          </el-col>
        </el-row>

        <el-divider content-position="left">Locales</el-divider>

        <el-row class="attr-row app-row" :gutter="12" type="flex" align="middle" style="margin-bottom: 20px;">
          <div style="width: 32px"></div>
          <el-tooltip effect="dark" content="Toggle all In Use" :enterable="false" placement="right">
            <div @click="toggleAllDoNotUse(false)" class="icon-dnu el-icon-check color-success" />
          </el-tooltip>
          <el-tooltip effect="dark" content="Toggle all Not In Use" :enterable="false" placement="right">
            <div @click="toggleAllDoNotUse(true)" class="icon-dnu el-icon-circle-close color-danger" />
          </el-tooltip>
          <span class="locale-name">All</span>
        </el-row>
        <el-row class="attr-row app-row" :gutter="12" type="flex" align="middle" v-for="app in base.data.children" :key="app.bundle_id">
          <country-flag :country="localeForApp(app).flag" />
          <el-tooltip effect="dark" content="App is in use" :enterable="false" placement="right" v-if="!app.do_not_use">
            <div @click="toggleDoNotUse(app)" class="icon-dnu el-icon-check color-success" />
          </el-tooltip>
          <el-tooltip effect="dark" content="App is marked 'Do Not Use'" :enterable="false" placement="right" v-else>
            <div @click="toggleDoNotUse(app)" class="icon-dnu el-icon-circle-close color-danger" />
          </el-tooltip>
          <router-link :to="{ name: 'AppPage', params: { bundleId: app.bundle_id }}" tag="el-link">
            <span class="locale-name">{{localeForApp(app).name}}</span>
          </router-link>
        </el-row>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { bundleIdent, setPageTitle } from '@/utils'

import CountryFlag from 'vue-country-flag'
import Icon8 from '@/components/icons/Icon8.vue'

export default {
  name: 'AppPage',
  async mounted () {
    try {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/base/' + this.$route.params.bundleId);
      if (!response.data.base) {
        return this.$router.replace({name: 'Overview'});
      }
      this.base.data = response.data.base;
      this.base.loaded = true;
      this.loading = false;
      setPageTitle(this.base.data.app_name);
    } catch (e) {
      this.loading = false;
      console.error(e);
      if (e.response && e.response.status === 403) {
        this.$message({
          message: "You don't have access to this resource. Try logging out and back in",
          duration: 0,
          type: 'error'
        });
      }
    }
  },
  data () {
    return {
      loading: true,
      base: {
        loaded: false,
        data: {}
      },
      gitUrlEditing: null,
      codePathEditing: null
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales,
      user: state => state.user,
    }),
    bigIcon () {
      if (this.base.data.icon_url)
        return this.base.data.icon_url.replace('42x42.', '');
      else
        return null;
    },
    translationRequired: {
      get () {
        return !!this.base.data.translation_type && !this.base.data.translation_type.includes('No');
      },
      set (val) {
        this.base.data.translation_type = val ? 'Direct' : null;
      }
    },
    recordingRequired: {
      get () {
        return this.translationRequired && !!this.base.data.audio_requirements;
      },
      set (val) {
        this.base.data.audio_requirements = val ? 'true' : null;
      }
    }
  },
  methods: {
    async deprecate () {
      try {
        await this.$confirm(`You're about to deprecate this application, which will also delete locale apps. Continue?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      } catch (e) {
        return;
      }

      if (this.base.data.deprecated) return;

      this.base.data.deprecated = true;
      let success = await this.saveAttrs();
      if (success) {
        this.$message({type: 'success', message: 'Deprecate completed'});
        this.$router.push({name: 'Overview'});
      } else {
        this.base.data.deprecated = false;
      }
    },
    async undeprecate () {
      try {
        await this.$confirm(`You're about to undeprecate this application, which will reinstate the base and apps. Continue?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      } catch (e) {
        return;
      }

      if (!this.base.data.deprecated) return;

      this.base.data.deprecated = false;
      let success = await this.saveAttrs();
      if (success) {
        this.$message({type: 'success', message: 'Base activated'});
        this.$router.push({name: 'Overview'});
      } else {
        this.base.data.deprecated = true;
      }
    },
    async createMissingLocales () {
      try {
        await this.$confirm(`You're about to create multiple language apps for this base. Continue?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      } catch (e) {
        return;
      }

      if (this.base.data.deprecated) return;

      try {
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/base_locales',
                                            {bundle_id: this.base.data.bundle_id});
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Updated.'});
        return true;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        return false;
      }
    },
    async createGitRepo () {
      try {
        this.loading = true;
        const resp = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/base_git_repo', {
          bundle_id: this.base.data.bundle_id,
        });
        this.base.data.git_url = resp.data.git_url;
        this.$message({type: 'success', message: 'Git Repo created and saved.'});
      } catch (e) {
        console.error(e);
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.data?.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      } finally {
        this.loading = false;
      }
    },
    async toggleDoNotUse (app) {
      if (!this.user.permissions.general.includes('admin') && !this.user.permissions.general.includes('base_edit')) {
        return;
      }

      try {
        let newState = !app.do_not_use;
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/app', {
          bundle_id: app.bundle_id,
          do_not_use: newState,
        });
        this.$message({type: 'success', message: 'Updated.'});
        app.do_not_use = newState;
      } catch (e) {
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async toggleAllDoNotUse (bool) {
      if (!this.user.permissions.general.includes('admin') && !this.user.permissions.general.includes('base_edit')) {
        return;
      }

      try {
        for (let app of this.base.data.children) {
          await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/app', {
            bundle_id: app.bundle_id,
            do_not_use: bool,
          });
          this.$message({type: 'success', message: 'Updated.'});
          app.do_not_use = bool;
        }
      } catch (e) {
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    localeForApp (app) {
      return this.locales.find(l => l.code === app.locale);
    },
    async saveAttrs () {
      try {
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/base',
                                            {app: this.base.data.bundle_id, ...this.base.data});
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Updated.'});
        return true;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        return false;
      }
    },
    async saveGitUrl () {
      let oldGitUrl = this.base.data.git_url;
      this.base.data.git_url = this.gitUrlEditing;
      let success = await this.saveAttrs();
      if (success) {
        this.gitUrlEditing = null;
      } else {
        this.base.data.git_url = oldGitUrl;
      }
    },
    async saveCodePath () {
      let oldCodePath = this.base.data.code_path;
      this.base.data.code_path = this.codePathEditing;
      let success = await this.saveAttrs();
      if (success)
        this.codePathEditing = null;
      else
        this.base.data.code_path = oldCodePath;
    },
    async saveBool (prop, value) {
      let success = await this.saveAttrs();
      if (!success) {
        this.base.data[prop] = !value;
      }
    }
  },
  components: {
    Icon8,
    CountryFlag
  },
  filters: {
    bundleIdent
  }
}
</script>

<style scoped lang="scss">
.el-header {
  width: 100%;

  span.flag {
    margin-left: -6px;
    transform: translateY(9px) scale(.5);
  }
  h2 {
    display: inline-block;
    margin: 0;
  }
  h3 {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .el-col:last-child {
    text-align: right;
  }

  .icon-locked, .icon-delete, .icon-add-location {
    font-size: 24px;
    line-height: 56px;
  }
  .icon-locked, .icon-delete {
    margin-right: 20px;
  }
  .icon-delete, .icon-add-location {
    cursor: pointer;
  }

  .edit-name-input {
    position: relative;
    display: inline-block;
    margin-left: 6px;
    min-width: 350px;

    .save-name {
      position: absolute;
      right: -55px;
      top: 5px;
    }
  }
}
.el-main {
  margin-top: 25px;
  word-break: break-word;

  .shared-content-tag + .el-tag,
  .shared-content-tag + .shared-content-input,
  .shared-content-tag + .el-button {
    margin-left: 10px;
  }

  .shared-content-input {
    width: 200px;
  }

  .attr-row +.attr-row {
    margin-top: 10px;

    .attr-edit {
      position: relative;
      min-height: 25px;

      .attr-edit-link {
        display: none;
        position: absolute;
        right: -5px;
      }

      &:hover .attr-edit-link {
        display: inline-block;
      }
    }
  }

  .app-row {
    margin-bottom: 5px;

    .flag {
      margin-right: 5px;
    }

    .locale-name {
      min-width: 50px;
    }

    .icon-dnu {
      font-size: 20px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
}
.el-autocomplete-suggestion {
  .app_name {
    margin-bottom: -15px;
  }
  .bundle_id {
    font-size: smaller;
  }
}
</style>