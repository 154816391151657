<template>
<el-container class="root">
  <el-card v-loading="loading">
    <div slot="header" class="clearfix">
      <span>Log in to Apptrack</span>
    </div>
    <center>
      <span class="error" v-if="success === 'false'">Failed to log in. Contact System Administrator<br /></span>
      <el-button class="leap" type="primary" @click="authenticate('leap')">
        <span>Leap Auth</span>
      </el-button>
    </center>
  </el-card>
</el-container>
</template>

<script>
import { mapState } from 'vuex'
import { setPageTitle } from '@/utils'

export default {
  name: 'Login',
  mounted () {
    if (this.user)
      this.$router.replace({name: 'Index'});
    setPageTitle("Login");
  },
  data () {
    return {
      loading: this.$route.query.success && this.$route.query.success !== 'false',
      success: this.$route.query.success
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    authenticate (provider) {
      var redir;
      if (provider === 'github')
        redir = process.env.VUE_APP_ROOT_API + '/login/github';
      else if (provider === 'google')
        redir = process.env.VUE_APP_ROOT_API + '/login/google';
      else if (provider === 'leap')
        redir = process.env.VUE_APP_ROOT_API + '/login/leap';

      var form = document.createElement('form');
      form.method = 'POST';
      form.action = redir;
      document.body.appendChild(form);
      form.submit();
      this.loading = true;
    }
  },
  watch: {
    'user' (user) {
      if (user)
        this.$router.replace({name: 'Index'});
    }
  }
}
</script>

<style scoped lang="scss">
.root > .el-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-button:not(.leap) span span {
    display: inline-block;
    color: white;
    margin-left: 10px;
    margin-right: 5px;
    transform: translateY(-6px);
  }

  .error {
    color: red;
  }

  .faded {
    opacity: 0.8;
    transform: scale(0.8);
  }
}
</style>