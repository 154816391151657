<template>
<el-form class="search-form" @submit.native.prevent="doSearch">
  <el-dropdown
  style="width: 100vh"
    placement="bottom-start" trigger="click"
    :hide-on-click="false"
    @visible-change="dropdownVisibleChange">
    <el-input
      clearable
      placeholder="Advanced Search"
      prefix-icon="el-icon-search"
      ref="searchInput"
      @clear="inputClear"
      v-model="searchInput" />

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>
        <span>Exact</span>
        <el-checkbox v-model="exact" label="Yes" border size="small"></el-checkbox>
      </el-dropdown-item>
      <el-dropdown-item>
        <span>Category</span>
        <el-autocomplete
          size="small"
          clearable
          v-model="category"
          :fetch-suggestions="queryCategories"
          placeholder="Input category" />
      </el-dropdown-item>
      <el-dropdown-item>
        <span>Date</span>
        <el-date-picker
          v-model="date"
          popper-class="date-picker-ontop"
          type="daterange"
          align="right"
          size="small"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :default-value="$moment().subtract(1, 'months')"
          :picker-options="datePickerOptions"
          @focus="dropdownKeepShow = true"
          @blur="datePickerBlur">
        </el-date-picker>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <el-button class="search-btn" @click="doSearch" :loading="loading">Search</el-button>
  <el-button type="danger" class="close-btn" icon="el-icon-close" @click="closeMe" circle />
</el-form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      searchInput: '',
      date: null,
      category: '',
      exact: null,

      dropdownKeepShow: false,
      datePickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        onPick () {

        }
      },
    }
  },
  props: {
    type: String
  },
  computed: {
    ...mapState({
      categories: state => state.categories
    }),
    categoryVals () {
      return this.categories.categories.map(c => { return {value: c.title} }).concat(
        Object.values(this.categories.groups).flatMap(g => g.categories.map(c => { return {value: c.title} }))
      );
    }
  },
  methods: {
    dropdownVisibleChange (visible) {
      if (!visible && this.dropdownKeepShow) {
        this.$refs.searchInput.$el.click();
      }
    },
    datePickerBlur () {
      setTimeout(() => this.dropdownKeepShow = false, 50);
    },
    inputClear () {
      this.category = '';
      this.date = null;
    },
    queryCategories (term, cb) {
      cb(this.categoryVals.filter(c => c.value.toLowerCase().indexOf(term.toLowerCase()) > -1));
    },
    async doSearch () {
      this.$refs.searchInput.blur();
      this.loading = true;
      try {
        let response = await this.$http.get(process.env.VUE_APP_ROOT_API + `/x/search/${this.type}`, {
          params: {
            term: this.searchInput,
            dateMin: this.date ? parseInt(this.date[0].getTime() / 1000) : null,
            dateMax: this.date ? parseInt(this.date[1].getTime() / 1000) : null,
            cat: this.category,
            exact: this.exact
          }
        });
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message || "unknown");
        let apps = response.data.results;
        this.$emit('search', apps);
      } catch (e) {
        console.error(e);
        this.$message({ message: "Error: " + e, type: 'error' });
      }
      this.loading = false;
    },
    closeMe () {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss">
.date-picker-ontop {
  z-index: 9999 !important;
}
</style>
<style scoped lang="scss">
ul.el-dropdown-menu {
  li > :first-child {
    width: 75px;
    display: inline-block;
  }

  li > :last-child {
    width: 280px;

    .el-input {
      width: auto;
    }
  }
}
.search-btn {
  margin-left: 5px;
}
.close-btn i::before {
  color: white !important;
}
</style>