<template>
<el-container direction="vertical">
  <el-menu class="menu" :default-active="activePage" @select="activePage = $event" mode="horizontal">
    <el-menu-item v-permission="'new'" index="version">New Version</el-menu-item>
    <el-menu-item v-permission="'admin'" index="application">New Application</el-menu-item>
    <el-menu-item v-permission="['admin', 'base_edit']" index="base">New Base App</el-menu-item>
    <el-menu-item v-permission="'admin'" index="locale">New Locale</el-menu-item>
    <el-menu-item v-permission="'admin'" index="collection">New Collection</el-menu-item>
    <el-menu-item v-permission="'licenses'" index="license">New License</el-menu-item>
  </el-menu>
  <el-main class="main-container" v-permission="['new', 'admin', 'licenses', 'base_edit']">
    <el-container direction="vertical" type="flex" justify-content="center">
      <!-- Version form -->
      <el-form v-if="activePage == 'version'" ref="versionForm" :rules="formRules" hide-required-asterisk :model="version" @submit.prevent="submitVersion" label-width="120px">
        <el-form-item label="Application" prop="bundle_id">
          <el-autocomplete
            v-model="version.bundle_id"
            :fetch-suggestions="autocompleteLocaleApp"
            value-key="bundle_id"
            placeholder="Search for application"
            @select="selectLocaleApp">
            <i class="el-icon-edit el-input__icon" slot="prefix" />
            <template slot-scope="{ item }">
              <div class="dropdown-value">{{ item.app_name }} (...{{ item.bundle_id|bundleIdent }})</div>
              <el-image style="width: 40px; height: 40px;" lazy :src="item.icon_url" />
            </template>
          </el-autocomplete>
        </el-form-item>

        <el-col :span="12">
          <el-form-item label="Version code" prop="version_code">
            <el-input-number size="medium" placeholder="40001" :min="version._minimum_version" v-model="version.version_code" />
            <span class="prev-value" v-if="version.version_code">Previous value: {{version.prev_code}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Version name" prop="version">
            <el-input size="medium" placeholder="4.0.1" v-model="version.version" />
            <span class="prev-value" v-if="version.version">Previous value: {{version.prev_name}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="6">
          <el-button size="small" @click="incrMajor">+ Major</el-button>
          <el-button size="small" @click="incrMinor">+ Minor</el-button>
        </el-col>

        <el-form-item style="margin-top: 60px; float: right;">
          <el-button type="success" :loading="working" @click="submitVersion">Create</el-button>
        </el-form-item>
      </el-form>

      <!-- Application form -->
      <el-form v-if="activePage == 'application'" ref="applicationForm" :rules="formRules" hide-required-asterisk :model="application" @submit.prevent="submitApplication" label-width="120px">
        <el-form-item label="Base Application" prop="base_bundle_id">
          <el-autocomplete
            v-model="application.base_bundle_id"
            :fetch-suggestions="autocompleteBaseApp"
            value-key="bundle_id"
            placeholder="Search for base application"
            @select="selectBaseApp">
            <i class="el-icon-edit el-input__icon" slot="prefix" />
            <template slot-scope="{ item }">
              <div class="dropdown-value">{{ item.app_name }} (...{{ item.bundle_id|bundleIdent }})</div>
              <el-image style="width: 40px; height: 40px;" lazy :src="item.icon_url" />
            </template>
          </el-autocomplete>
        </el-form-item>

        <el-form-item label="Bundle ID" prop="bundle_id">
          <el-input placeholder="New Bundle ID" v-model="application.bundle_id" />
        </el-form-item>

        <el-form-item label="App Name" prop="app_name">
          <el-input placeholder="Application Name" v-model="application.app_name" />
        </el-form-item>

        <el-col :span="12">
          <el-form-item label="Version code" prop="version_code">
            <el-input-number size="medium" placeholder="40001" v-model="application.version_code" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Version name" prop="version">
            <el-input size="medium" placeholder="4.0.1" v-model="application.version" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="6">
          <el-button size="small" @click="incrMajor">+ Major</el-button>
          <el-button size="small" @click="incrMinor">+ Minor</el-button>
        </el-col>

        <el-form-item style="margin-top: 60px; float: right;">
          <el-button type="success" :loading="working" @click="submitApplication">Create</el-button>
        </el-form-item>
      </el-form>

      <!-- Base form -->
      <el-form v-if="activePage == 'base'" ref="baseForm" :rules="formRules" hide-required-asterisk :model="base" @submit.prevent="submitBase" label-width="120px">
        <el-form-item label="Bundle ID" prop="bundle_id">
          <el-input placeholder="New Bundle ID" v-model="base.bundle_id" />
        </el-form-item>

        <el-form-item label="App Name" prop="app_name">
          <el-input placeholder="Application Name" v-model="base.app_name" />
        </el-form-item>

        <el-form-item label="Category" prop="category">
          <el-autocomplete
            v-model="base.category"
            :fetch-suggestions="autocompleteCategory"
            value-key="name"
            placeholder="Search for category">
            <i class="el-icon-edit el-input__icon" slot="prefix" />
            <template slot-scope="{ item }">
              <div class="dropdown-value">{{ item.name }}</div>
            </template>
          </el-autocomplete>
          <el-alert
            v-if="isNewCategory"
            :title="'Will create new category ' + base.category"
            type="warning"
            show-icon
            :closable="false" />
        </el-form-item>

        <el-form-item label="Translation type" prop="translation">
          <el-radio-group v-model="base.translation" size="medium">
            <el-radio-button label="Direct translation" />
            <el-radio-button label="Content recreation" />
            <el-radio-button label="No translation" />
          </el-radio-group>
        </el-form-item>

        <el-col :xs="24" :span="12">
          <el-form-item label="">
              <el-checkbox v-model="base.recording" label="Recording required" border size="medium" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :span="12">
          <el-form-item label="">
              <el-checkbox v-model="base.autocreate" label="Autocreate locale apps" border size="medium" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :span="12">
          <el-form-item label="">
              <el-checkbox v-model="base.gitrepo_create" label="Create git code repo (only if new standard)" border size="medium" />
          </el-form-item>
        </el-col>

        <el-form-item style="margin-top: 60px; float: right;">
          <el-button type="success" :loading="working" @click="submitBase">Create</el-button>
        </el-form-item>
      </el-form>

      <!-- Locale form -->
      <el-form v-if="activePage == 'locale'" ref="localeForm" :rules="formRules" hide-required-asterisk :model="new_locale" @submit.prevent="submitLocale" label-width="120px">

        <el-form-item label="Localized Name" prop="locale_name">
          <el-input placeholder="Norsk" v-model="new_locale.locale_name" />
        </el-form-item>

        <el-col :xs="24" :span="12">
          <el-form-item label="Locale Code" prop="locale_code">
            <el-input placeholder="NO" v-model="new_locale.locale_code" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :span="12">
          <el-form-item label="Flag" prop="flag_code">
            <el-autocomplete
              v-model="new_locale.flag_code"
              :fetch-suggestions="autocompleteFlag"
              value-key="name"
              placeholder="Search for flag">
              <i class="el-icon-edit el-input__icon" slot="prefix" />
              <template slot-scope="{ item }">
                <div class="dropdown-value">{{ item.name }}</div>
                <country-flag :country="item.name" />
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :span="12">
          <el-form-item label="">
              <el-checkbox v-model="new_locale.autocreate" label="Autocreate all apps" border size="medium" />
          </el-form-item>
        </el-col>

        <el-form-item style="margin-top: 60px; float: right;">
          <el-button type="success" :loading="working" @click="submitLocale">Create</el-button>
        </el-form-item>
      </el-form>

      <collection v-if="activePage == 'collection'" />
      <license v-if="activePage == 'license'" />

    </el-container>
  </el-main>
</el-container>
</template>

<script>
import { bundleIdent, latestVersion, incrementVersionCode, versionCodeToString, setPageTitle } from '@/utils'
import { mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'
import Collection from './new/Collection.vue'
import License from './new/License.vue'

export default {
  data () {
    return {
      activePage: this.$route.params.index || null,
      localeApplications: [],
      baseApplications: [],
      categories: [],
      flags: [],

      formRules: {
        bundle_id: [
          { required: true, message: 'Bundle ID is required', trigger: 'submit' },
          { validator: (rule, value, cb) => {
            if (value.match(/[^a-z0-9_\-.]/g))
              cb(new Error("Invalid characters in bundle id"));
            else if (!value.match(/^(no\.leaplearning\.)/g) && !value.match(/^org\.africastartup\.htmm_/g))
              cb(new Error("ID does not start with no.leaplearning."));
            else if (!value.includes("africastartup.htmm") && !value.match(/(.+)\.(.+)\.(.+)\.(.+)/g))
              cb(new Error("ID should contain at least 4 parts (no.leaplearning.category.app)"));
            else
              cb();
          }, trigger: 'blur' }
        ],
        app_name: [{ required: true, message: 'Application name is required', trigger: 'submit' }],
        category: [{ required: true, message: 'Category is required', trigger: 'submit' }],
        version_code: [{ required: true, message: 'Version code is required', trigger: 'submit' }],
        version: [{ required: true, message: 'Version name is required', trigger: 'submit' }],
        translation: [{ required: true, message: 'Translation type is required', trigger: 'submit' }],
        locale_name: [{ required: true, message: 'Locale name is required', trigger: 'submit' }],
        locale_code: [{ required: true, message: 'Locale code is required', trigger: 'submit' }],
        flag_code: [{ required: true, message: 'Flag code is required', trigger: 'submit' }]
      },
      working: false,

      version: {
        bundle_id: this.$route.params.bundleId,
        version: null,
        version_code: null
      },
      application: {
        bundle_id: null,
        app_name: null,
        version: null,
        version_code: null
      },
      base: {
        bundle_id: this.$route.params.dev ? this.$route.params.dev.bundleId : null,
        app_name: this.$route.params.dev ? this.$route.params.dev.appName : null,
        category: this.$route.params.dev ? this.$route.params.dev.category : null,
        translation: null,
        recording: false,
        autocreate: true,
        gitrepo_create: false,
        dev_version_code: this.$route.params.dev ? this.$route.params.dev.versionCode : null,
        dev_approve: this.$route.params.dev ? this.$route.params.dev.approve : false
      },
      new_locale: {
        locale_name: null,
        locale_code: null,
        flag_code: null,
        autocreate: true
      }
    }
  },
  async mounted () {
    this.activePage = this.initialTab();
    setPageTitle("New");

    if (["version", "application", "base", "locale"].includes(this.activePage)) {
      try {
        let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/add');
        this.localeApplications = response.data.apps;
        this.baseApplications = response.data.base_apps;
        this.categories = response.data.categories;
        this.flags = response.data.flags;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }

      // Version: Prefill code and name if bundle is prefilled
      if (this.version.bundle_id) {
        this.selectLocaleApp(this.localeApplications.find(a => a.bundle_id === this.version.bundle_id));
      }
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales,
      user: state => state.user,
    }),
    isNewCategory () {
      return !!this.base.category && !this.categories.find(c => c.toLowerCase() === this.base.category.toLowerCase());
    }
  },
  methods: {
    versionCodeToString,
    incrementVersionCode,

    initialTab () {
      if (this.user.permissions.general.includes("admin") || this.user.permissions.general.includes("base_edit")) {
        return "base";
      } else if (this.user.permissions.general.includes("licenses")) {
        return "license";
      } else {
        return "version";
      }
    },

    incrMajor () {
      if (this.version.version_code) {
        this.version.version_code = incrementVersionCode(this.version.version_code, 'major');
        this.version.version = versionCodeToString(this.version.version_code);
      }
      if (this.application.version_code) {
        this.application.version_code = incrementVersionCode(this.application.version_code, 'major');
        this.application.version = versionCodeToString(this.application.version_code);
      }
    },
    incrMinor () {
      if (this.version.version_code) {
        this.version.version_code = incrementVersionCode(this.version.version_code, 'minor');
        this.version.version = versionCodeToString(this.version.version_code);
      }
      if (this.application.version_code) {
        this.application.version_code = incrementVersionCode(this.application.version_code, 'minor');
        this.application.version = versionCodeToString(this.application.version_code);
      }
    },

    autocompleteLocaleApp (query, qb) {
      if (!query) return qb([]);
      qb(this.localeApplications.filter(a =>
        a.app_name.toLowerCase().includes(query.toLowerCase()) || a.bundle_id.toLowerCase().includes(query.toLowerCase())
      ));
    },
    selectLocaleApp (app) {
      this.version.prev_code = latestVersion(app).version_code;
      this.version.prev_name = latestVersion(app).version;
      this.version.version_code = incrementVersionCode((latestVersion(app).version_code || 0), 'patch');
      this.version.version = versionCodeToString(this.version.version_code);
      this.version._minimum_version = this.version.version_code;
    },
    async submitVersion () {
      let valid = await this.$refs.versionForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/version', {...this.version});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Version created'});
        this.$router.push({name: 'AppPage', params: {bundleId: this.version.bundle_id}});
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    },

    autocompleteBaseApp (query, qb) {
      if (!query) return qb([]);
      qb(this.baseApplications.filter(a =>
        a.app_name.toLowerCase().includes(query.toLowerCase()) || a.bundle_id.toLowerCase().includes(query.toLowerCase())
      ));
    },
    selectBaseApp (app) {
      this.application.bundle_id = app.bundle_id.replace('com.alphabetking', 'no.leaplearning');
      if (this.locale.code !== 'EN')
        this.application.bundle_id += '.' + this.locale.code.toLowerCase();
      this.application.version_code = 40000;
      this.application.version = "4.0.0";
    },
    async submitApplication () {
      let valid = await this.$refs.applicationForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/app', {...this.application});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Application created'});
        this.$router.push({name: 'AppPage', params: {bundleId: this.application.bundle_id}});
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    },

    autocompleteCategory (query, qb) {
      if (!query) return qb([]);
      qb(this.categories
          .filter(c => c.toLowerCase().includes(query.toLowerCase()))
          .map(c => Object.assign({}, {name: c}))
      );
    },
    async submitBase () {
      let valid = await this.$refs.baseForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/base', {...this.base});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        // this.$router.push({name: 'BasePage', params: {bundleId: this.application.bundle_id}});
        this.$message({type: 'success', message: 'Base application created'});
        // Set locale and category to newly created base, so it's immediately visible (and updated from leapstore)
        this.$store.dispatch('setLocale', this.locales.find(l => l.code === 'EN'));
        this.$store.dispatch('setLastCategory', this.base.category);
        this.$router.push("/");
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    },

    autocompleteFlag (query, qb) {
      if (!query) return qb([]);
      qb(this.flags
          .filter(f => f.toLowerCase().includes(query.toLowerCase()))
          .map(f => Object.assign({}, {name: f.substr(0, 2)}))
      );
    },
    async submitLocale () {
      let valid = await this.$refs.localeForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/locale', {...this.new_locale});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Locale created'});
        this.$store.dispatch('refreshLocales');
        this.$router.push("/");
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    }
  },
  watch: {
    'version.version_code' () {
      if (this.version.version_code)
        this.version.version = versionCodeToString(this.version.version_code);
    },
    'application.version_code' () {
      if (this.application.version_code)
        this.application.version = versionCodeToString(this.application.version_code);
    }
  },
  filters: {
    bundleIdent
  },
  components: {
    CountryFlag,
    Collection,
    License,
  }
}
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.el-main {
  overflow: visible;

  > .el-container {
    align-items: center;

    .el-form {
      max-width: 650px;

      .el-autocomplete {
        display: block;
      }

      .prev-value {
        position: absolute;
        left: 0;
        bottom: -32px;
        opacity: 0.7;
      }
    }
  }
}
.dropdown-value {
  display: inline-block;
}
.dropdown-value + .el-image {
  display: inline-block;
  float: right;
}
.dropdown-value + .flag {
  display: inline-block;
  float: right;
}
</style>