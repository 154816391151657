<template>
  <el-container class="license-page" direction="vertical" v-loading="loading">
    <h2 v-if="!error && license.loaded">Edit license</h2>
    <license v-if="!error && license.loaded" :license="license.data" />
    <el-result v-else-if="error" icon="error" title="Error" :subTitle="error">
      <template slot="extra">
        <el-button type="primary" size="medium">Back</el-button>
      </template>
    </el-result>
  </el-container>
</template>

<script>
import License from './new/License.vue'

export default {
  data () {
    return {
      loading: true,
      error: null,
      license: {
        data: null,
        loaded: false,
      }
    }
  },
  async mounted() {
    try {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/licenses/' + this.$route.params.licenseId);
      if (!response.data.license) {
        throw new Error("No license found");
      }
      this.license.data = response.data.license;
      this.license.loaded = true;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.error = e.response?.data?.message ?? "Failed to load license";
      console.error(e);
      if (e.response && e.response.status === 403) {
        this.$message({
          message: "You don't have access to this resource. Try logging out and back in",
          duration: 0,
          type: 'error'
        });
      }
    }
  },
  components: {
    License,
  },
}
</script>

<style scoped lang="scss">
.license-page {
  max-width: 800px;
  margin: 0 auto;
}
</style>