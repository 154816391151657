<template>
  <el-container>
    <el-header style="z-index: 2">
      <el-menu :default-active="activePage" :router="true" mode="horizontal">
        <router-link class="logo" to="/">Apptrack</router-link>

        <div v-if="user" class="center" style="outline: none;">
          <locale-select style="z-index: 2000" />
        </div>

        <el-menu-item class="menu-large" v-if="user" style="float: right;" @click="logout">Logout</el-menu-item>
        <el-menu-item class="menu-large" v-if="user" v-permission="['new', 'licenses', 'base_edit']" index="new" style="float: right;">New</el-menu-item>
        <el-submenu class="menu-large" v-if="user" :show-timeout="50" index="explore" style="float: right;">
          <template slot="title">Explore</template>
          <el-menu-item index="/">Apps</el-menu-item>
          <el-menu-item index="overview" v-permission="['overview', 'developer']">Bases</el-menu-item>
          <el-menu-item index="licenses" v-permission="['licenses']">Licenses</el-menu-item>
          <el-menu-item index="development" v-permission="['developer']">Development</el-menu-item>
          <!-- <el-menu-item index="bugs">Bugs</el-menu-item> -->
        </el-submenu>
        <el-menu-item class="menu-large" index="users" style="float: right;" v-permission="['admin']">Users</el-menu-item>
        <el-menu-item class="menu-large" v-if="user && pageSearchable" style="float: right;" @click="showSearch = !showSearch">Search</el-menu-item>

        <el-submenu class="menu-small" mode="vertical">
          <template slot="title">|||</template>

          
          <el-menu-item v-if="user && pageSearchable" @click="showSearch = !showSearch">Search</el-menu-item>
          <el-menu-item index="/">Apps</el-menu-item>
          <el-menu-item index="overview" v-permission="['overview', 'developer']">Bases</el-menu-item>
          <el-menu-item index="licenses" v-permission="['licenses']">Licenses</el-menu-item>
          <el-menu-item index="users" v-permission="['admin']">Users</el-menu-item>
          <el-menu-item v-if="user" v-permission="['new', 'licenses', 'base_edit']" index="new">New</el-menu-item>
          <el-menu-item v-if="user" @click="logout">Logout</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-header>
    <el-container class="search" v-show="showSearch">
      <advanced-search :categories="[]" :type="searchType" @search="onSearch" @close="searchClose" />
    </el-container>

    <el-container class="page">
      <router-view ref="page"></router-view>

      <el-dialog custom-class="page-modal" top="5vh" width="85%" :lock-scroll="true" :modal="true" :close-on-press-escape="true"
        title="" :visible.sync="showModal" :before-close="handleModalClose">
        <router-view name="modal"></router-view>
      </el-dialog>

    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import LocaleSelect from '@/components/LocaleSelect.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'

export default {
  name: 'app',
  data() {
    return {
      activePage: "/",
      showModal: this.$route.meta.showModal,
      showSearch: false
    }
  },
  methods: {
    logout () {
      this.$http.get(process.env.VUE_APP_ROOT_API + '/x/logout');
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },
    handleModalClose () {
      if (this.$route.name === 'BasePage')
        this.$router.push({name: 'Overview'});
      else if (this.$route.name === 'LicensePage')
        this.$router.push({name: 'Licenses'});
      else
        this.$router.push({name: 'Index'});
    },
    onSearch (results) {
      if (this.$refs.page && this.$refs.page.onSearch) {
        this.$refs.page.onSearch(results);
      }
    },
    searchClose () {
      this.showSearch = false;
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      user: state => state.user
    }),
    searchType () {
      if (this.$route.name === 'Overview') {
        return 'base';
      } else {
        return 'app';
      }
    },
    pageSearchable () {
      return ["Overview", "Index"].includes(this.$route.name);
    }
  },
  async created () {
    // For whatever reason `this` is undefined inside interceptor so we need to capture it manually
    let vueThis = this;
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        err = err.response || err;
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          vueThis.$store.dispatch('logout');
          vueThis.$router.replace('/login');
        }
        throw err;
      });
    });

    // Check if we should set locale from http param
    if (this.$route.query.l) {
      this.$store.dispatch('setLocale', {code: this.$route.query.l.toUpperCase(), flag: this.$route.query.l.toLowerCase()});
    }

    this.$http.defaults.params = {};
    this.$http.defaults.params['l'] = this.locale.code;

    // Check if we should log user in
    let loggedIn = await this.$store.dispatch('refreshUser');
    if (loggedIn && this.$route.name === 'Login') {
      this.$router.replace('/');
    } else if (!loggedIn && this.$route.name !== 'Login') {
      this.$router.replace('/login');
    }
  },
  watch: {
    '$route.meta' ({showModal}) {
      this.showModal = showModal
    },
    'locale' () {
      this.$http.defaults.params['l'] = this.locale.code;

      // if (this.$route.name !== 'Index') {
      //   this.$router.push({name: 'Index'})
      // }
    }
  },
  components: {
    LocaleSelect,
    AdvancedSearch
  }
}
</script>

<style lang="scss">
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body > .v-modal {
  opacity: 0.1;
}
* {
  word-break: break-word !important;
}
.page .page-modal {
  min-height: 87vh;

  .el-dialog__header {
    padding: 0 !important;
  }
}
.el-table__column-filter-trigger i {
  color: #666 !important;
  font-size: 16px !important;
  font-weight: 600;
}
</style>
<style scoped lang="scss">
.el-menu {
  width: 100%;

  .logo {
    float: left;
    height: 60px;
    line-height: 50px;
    font-size: x-large;
    text-decoration: none;
  }

  .center {
    display: inline-block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
}
.el-menu-item.menu-large, .el-submenu.menu-large {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.el-submenu.menu-small {
  float: right;

  @media screen and (min-width: 768px) {
    display: none;
  }
}
.search {
  > form {
    margin: 10px auto 0 auto;
  }
}
@media screen and (min-width: 992px) {
  .page {
    margin-left: 50px;
    margin-right: 50px;
  }
}
</style>
