<template>
<el-popover
  :placement="direction"
  width="350"
  trigger="hover"
  show-overflow-tooltip
  v-if="show"
  @show="onShow">
  <el-table :data="sortedData" v-loading="loading" :max-height="220">
    <el-table-column width="40" prop="duplicates" label="" v-if="sortedData.some(a => a.duplicates)" />
    <el-table-column min-width="75" prop="timestamp" label="Date">
      <template slot-scope="scope">{{scope.row.timestamp|moment('Do MMM, YYYY')}}</template>
    </el-table-column>
    <el-table-column min-width="120" prop="user.name" label="User" />
  </el-table>
  <i slot="reference" class="el-icon-time" />
</el-popover>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      loadTime: null,
      data: []
    }
  },
  props: {
    show: {type: Boolean, default: true},
    direction: String,
    type: String,
    id: [String, Number],
    field: String
  },
  computed: {
    sortedData () {
      // Merge duplicates (same user, action, column, and day)
      let deduped = [];
      for (let entry of this.data) {
        entry.timestamp = this.$moment(entry.timestamp);
        let existing = deduped.find(e =>
          (e.user === null || entry.user == null || e.user.id === entry.user.id)
            && e.columns === entry.columns
            && e.action === entry.action
            && e.timestamp.isSame(entry.timestamp, 'day')
        );
        if (existing) {
          existing.duplicates = (existing.duplicates || 1) + 1;
        } else {
          deduped.push(entry);
        }
      }
      // Return sorted by time, more recent first
      return deduped.sort((a, b) => b.timestamp - a.timestamp);
    }
  },
  methods: {
    async onShow () {
      if (this.loadTime && this.loadTime.isAfter(this.$moment().subtract(10, 'minutes')) && this.data)
        return;

      try {
        let response = await this.$http.get(`${process.env.VUE_APP_ROOT_API}/x/audit/${this.type}/${this.id}`, {
          params: {field: this.field}
        });
        this.data = response.data;
        this.loading = false;
        this.loadTime = this.$moment();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>

<style lang="scss">

</style>