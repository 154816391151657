<template>
<div class="version-attr">
  <el-radio-group v-model="internalValue" >
    <el-radio class="radio-true" size="small" :label="true" border>
      <i class="el-icon-loading" v-show="loading" />
      <span v-show="!loading">{{label(0)}}</span>
    </el-radio>
    <el-radio class="radio-null" size="small" :label="null" border v-if="tristate">
      <i class="el-icon-loading" v-show="loading" />
      <span v-show="!loading">{{label(1)}}</span>
    </el-radio>
    <el-radio class="radio-false" size="small" :label="false" border>
      <i class="el-icon-loading" v-show="loading" />
      <span v-show="!loading">{{label(2)}}</span>
    </el-radio>
  </el-radio-group>
</div>
</template>

<script>
export default {
  props: {
    value: [Number, Boolean, null, undefined],
    loading: Boolean,
    disabled: Boolean,
    tristate: Boolean,
    labels: Array
  },
  methods: {
    label(i) {
      let labels = this.labels;
      if (!this.labels)
        labels = ["Yes", "", "No"];
      return labels[i];
    }
  },
  computed: {
    internalValue: {
      get () {
        return (this.value === undefined || this.value === null && this.tristate) ? null : !!this.value;
      },
      set (val) {
        if (!this.disabled) {
          this.$emit('input', val);
          this.$emit('change', {value: val, previous: this.internalValue});
        }
      }
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/style-variables.scss";

.version-attr .el-radio {
  margin-right: 5px;
}
.version-attr .el-radio:not(.is-disabled) {
  &.radio-true.is-checked {
    border-color: $--color-success;

    .el-radio__inner {
      border-color: $--color-success;
      background: $--color-success;
    }
    .el-radio__label {
      color: $--color-success;
    }
  }
  &.radio-false.is-checked {
    border-color: $--color-danger;

    .el-radio__inner {
      border-color: $--color-danger;
      background: $--color-danger;
    }
    .el-radio__label {
      color: $--color-danger;
    }
  }
  &.radio-null.is-checked {
    border-color: $--color-grey;

    .el-radio__inner {
      border-color: $--color-grey;
      background: $--color-grey;
    }
    .el-radio__label {
      color: $--color-grey;
    }
  }
}
</style>