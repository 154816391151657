<template>
<el-form ref="collectionForm" :rules="formRules" hide-required-asterisk :model="new_collection" @submit.prevent="submitCollection" label-width="120px">
  <el-col :span="24">
    <el-form-item label="Title" prop="title">
      <el-input placeholder="Norsk Lese og Skrive" v-model="new_collection.title" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="Locale Code" prop="locale_code">
      <el-input :placeholder="locale.code" v-model="new_collection.locale_code" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="URL Slug" prop="slug">
      <el-tooltip
        effect="dark"
        placement="right"
        :enterable="false"
        manual
        :value="showSlugHint">
        <div slot="content">
          Some examples (try to always use these):<br />
          Literacy: lit<br />
          Numeracy: num<br />
          Science: science<br />
          HTMM: htmm<br />
          Standards: std<br />
          Others: Use the name in lowercase, shortened if long, but be consistent
        </div>
        <el-input placeholder="ex. lit" v-model="new_collection.slug" @focus="showSlugHint = true" @blur="showSlugHint = false" />
      </el-tooltip>
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <el-form-item label="Launcher layout" prop="layout">
      <el-radio-group v-model="new_collection.layout" size="medium">
        <el-radio-button label="normal">Normal</el-radio-button>
        <el-radio-button label="tvet">TVET</el-radio-button>
        <el-radio-button label="htmm">HTMM</el-radio-button>
      </el-radio-group>
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <el-form-item label="Categories" prop="categories">
      <el-cascader
        v-model="new_collection.categories"
        placeholder="Search/Select categories"
        size="medium"
        :options="categories"
        :props="{ multiple: true }"
        filterable>
        <template slot-scope="{ node, data }">
          <span v-if="!node.isLeaf">{{ data.label }}</span>
          <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
          <div v-if="node.isLeaf" class="category-leaf">
            <span class="category-leaf-title">{{ data.label }}</span>
            <div>
              <span class="category-leaf-base">{{ data.baseSlug }}</span>
              <span class="category-leaf-base">{{ data.baseLabel }}</span>
            </div>
          </div>
        </template>
      </el-cascader>
    </el-form-item>
  </el-col>

  <el-form-item style="margin-top: 60px; float: right;">
    <el-button type="success" :loading="working" @click="submitCollection">Create</el-button>
  </el-form-item>
</el-form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      categories: [],

      formRules: {
        title: [{ required: true, message: 'Title is required', trigger: 'submit' }],
        locale_code: [{ required: true, message: 'Locale code is required', trigger: 'submit' }],
        slug: [{ required: true, message: 'A slug is required', trigger: 'submit' }],
        categories: [{ required: true, message: 'At least one category is required', trigger: 'submit' }]
      },
      working: false,
      showSlugHint: false,

      new_collection: {
        title: null,
        locale_code: null,
        slug: null,
        layout: "normal",
        categories: [],
      }
    }
  },
  async mounted () {
    try {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/categories');
      let categoryOptions = [];
      for (let category of response.data.categories) {
        const locale = this.locales.find(l => l.code === category.locale);
        if (!locale) {
          continue;
        }
        if (!categoryOptions.find(co => co.value === locale.code)) {
          categoryOptions.push({value: locale.code, label: locale.name});
        }
        const localeOption = categoryOptions.find(co => co.value === locale.code);
        localeOption.children = localeOption.children || [];
        localeOption.children.push({
          label: category.title,
          baseLabel: category.base_category.title,
          baseSlug: category.base_category.slug,
          value: category.id
        });
      }
      this.categories = categoryOptions;
    } catch (e) {
      console.error(e);
      this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales
    })
  },
  methods: {
    async submitCollection () {
      let valid = await this.$refs.collectionForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/collection', {...this.new_collection});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Collection created'});
        this.$router.push("/");
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  justify-content: center;
}
.el-cascader {
  width: 100%
}
.category-leaf {
  display: flex;
  flex-direction: column;
  line-height: 15px;
}
.category-leaf-title {
  line-height: 15px;
  font-size: 13px;
  display: block;
}
.category-leaf-base {
  display: inline-block;
  line-height: 15px;
  font-size: 11px;
  margin-right: 5px;
}
</style>