import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
})

export default new Vuex.Store({
  plugins: [vuexSessionStorage.plugin],
  state: {
    locale: {code: 'EN', flag: 'en'},
    locales: [],
    user: null,
    categories: {categories: [], grouped: [], groups: []},
  },
  mutations: {
    setLocale (state, newLocale) {
      state.locale = newLocale
      state.user.last_locale = newLocale.code
    },
    setLocales (state, locales) {
      state.locales = locales
    },
    setUser (state, userData) {
      if (!userData) {
        state.user = null
      } else {
        state.user = userData
      }
    },
    setLastCategory (state, category) {
      state.user.last_category = category;
    },
    setCategories (state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    setLocale (context, locale) {
      context.commit('setLocale', locale)
    },
    async setLastCategory (context, category) {
      context.commit('setLastCategory', category);
      try {
        await Vue.axios.post(process.env.VUE_APP_ROOT_API + '/update/last_category', {category: category});
      } catch (e) {
        console.error(e);
      }
    },
    updateLocales (context, locales) {
      context.commit('setLocales', locales)
    },
    async refreshLocales (context) {
      try {
        let response = await Vue.axios.get(process.env.VUE_APP_ROOT_API + '/x/locales');
        context.commit('setLocales', response.data.locales);
      } catch (e) {
        console.error(e);
      }
    },
    async refreshCategories(context) {
      try {
        let response = await Vue.axios.get(process.env.VUE_APP_ROOT_API + '/x/home');
        context.commit('setCategories', response.data);
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 403) {
          Vue.$message({
            message: "You don't have access to this resource. Try logging out and back in",
            duration: 0,
            type: 'error'
          });
        }
      }
    },
    async refreshUser(context) {
      try {
        let userData = await Vue.axios.get(process.env.VUE_APP_ROOT_API + '/x/user');
        if (userData.status === 200) {
          context.commit('setUser', userData.data.user);
          context.commit('setLocale', userData.data.locale);
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    logout (context) {
      context.commit('setUser', null)
    }
  }
})
