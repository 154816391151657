<template>
<div style="text-align: center;z-index: 999;">
  <country-flag class="current-flag" :country="locale.flag|lowercase" />
  <el-tag effect="dark" type="info" style="transform: translateY(-12px);" @click="show = !show"> {{locale.name}}</el-tag>

  <el-card v-if="show" v-on-clickaway="hide">
    <el-row :gutter="10" v-if="locales[0].buildable != undefined || locales[0].bugs != undefined">
      <el-col :span="3"><icon-8 name="bug" color="error" width="24" height="24" v-if="locales[0].bugs != undefined" /></el-col>
      <el-col :span="3"><icon-8 name="wrench" width="21" height="21" v-if="locales[0].buildable != undefined" /></el-col>
      <el-col :span="5"></el-col>
      <el-col :span="13"></el-col>
    </el-row>
    <el-row :gutter="10" v-for="l in locales" :key="l.code">
      <div style="display: flex; align-items: center; width: 100%;" @click="setLocale(l)">
        <template v-if="locales[0].buildable != undefined || locales[0].bugs != undefined">
          <el-col :span="3">{{l.bugs}}</el-col>
          <el-col :span="3">{{l.buildable}}</el-col>
          <el-col :span="6" class="flag-col">
            <span :class="{dot: true, green: l.public, red: !l.public}"></span>
            <country-flag :country="l.flag|lowercase" />
          </el-col>
          <el-col :span="12" style="text-align: left;">[{{l.code}}] {{l.name}}</el-col>
        </template>
        <template v-else>
          <el-col :span="5"><country-flag :country="l.flag|lowercase" /></el-col>
          <el-col :span="19" style="text-align: left;">[{{l.code}}] {{l.name}}</el-col>
        </template>
      </div>
    </el-row>
  </el-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'
import Icon8 from '@/components/icons/Icon8.vue'
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [ clickaway ],
  data () {
    return {
      show: false
    }
  },
  async mounted () {
    this.$store.dispatch('refreshLocales');
  },
  methods: {
    setLocale (locale) {
      this.$store.dispatch('setLocale', locale);
      this.hide();
      this.$http.post(process.env.VUE_APP_ROOT_API + '/update/locale', {locale: locale.code});
    },
    hide () {
      if (this.show)
        this.show = false;
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales
    })
  },
  components: {
    CountryFlag,
    Icon8
  }
}
</script>

<style scoped lang="scss">
.current-flag {
  transform: scale(.7) !important;
  margin-right: -10px !important;
  margin-bottom: -4px !important;
}
.el-tag {
  cursor: pointer;
}
.el-card {
  min-width: 380px;
  max-height: 75vh;
  overflow: scroll;

  .el-row {
    display: flex;
    align-items: center;

    .el-col {
      position: relative;
      color: rgba(0, 0, 0, 0.33);
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &:hover .el-col {
      color: inherit;
    }
  }

  .dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;

    &.green {
      background-color: lighten(green, 10%);
    }

    &.red {
      background-color: darken(red, 10%);
    }
  }
}
</style>