<template>
  <el-container direction="vertical" v-loading="loading" style="margin-right: 20px;">
    <el-header v-if="app.loaded">
      <el-row type="flex" :gutter="20">
        <el-col :span="2">
          <el-image style="width: 75px; height: 75px;" :src="bigIcon">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-col>
        <el-col :span="16">
          <h3 v-permission="['developer', 'overview', 'base_edit']"><router-link :to="{ name: 'BasePage', params: { bundleId: app.data.base.bundle_id }}" tag="el-link">{{app.data.base.app_name}}</router-link> -</h3>
          <country-flag :country="flagCode" />
          <h2>{{app.data.app_name}}
            <el-tooltip effect="dark" content="Name equal to base" placement="bottom" :enterable="false" v-show="!appNameTranslated(app.data) && !app.data.allow_base_name">
              <i class="el-icon-warning-outline color-danger" />
            </el-tooltip>
            <el-tooltip effect="dark" content="Edit this in Translate" placement="bottom" :enterable="false">
              <i class="el-icon-info color-primary" />
            </el-tooltip>
          </h2>
          <h4>{{categories.join(", ")}}</h4>
        </el-col>
        <el-col :span="5">
          <el-tooltip
              class="build-button" effect="dark" content="Trigger a full build with updated Translate data" 
              :enterable="false" placement="left" v-permission="['developer']"
              v-if="app.data.base.translate_capable && app.completion && (app.completion.percent || 0) >= 50">
            <el-button type="success" :loading="buildButton.loading" @click="triggerBuild">Build</el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="App is in use" :enterable="false" placement="left" v-if="!app.data.do_not_use">
            <div @click="toggleDoNotUse" class="icon-dnu el-icon-check color-success" />
          </el-tooltip>
          <el-tooltip effect="dark" content="App is marked 'Do Not Use'" :enterable="false" placement="left" v-else>
            <div @click="toggleDoNotUse" class="icon-dnu el-icon-circle-close color-danger" />
          </el-tooltip>
          <el-tooltip v-permission="'admin'" effect="dark" content="Delete" :enterable="false" placement="left">
            <div @click="deleteApp" class="icon-delete el-icon-delete color-danger" />
          </el-tooltip>
        </el-col>
      </el-row>
    </el-header>

    <el-main v-if="app.loaded">
      <el-container direction="vertical">
        <el-row :gutter="16" type="flex" align="middle">
          <el-col :span="1"><icon-8 name="tag" width="24" height="24" /></el-col>
          <el-col :span="2"><span>Bundle ID</span></el-col>
          <el-col :span="17"><span>{{app.data.bundle_id}}</span><span style="opacity: 0.6;"> - {{app.data.base.bundle_id}}</span></el-col>
        </el-row>
        <el-row :gutter="16" type="flex" align="middle" v-permission="['developer', 'base_edit']">
          <el-col :span="1"><icon-8 name="flag2" width="18" height="18" /></el-col>
          <el-col :span="2"><span>Flag position</span></el-col>
          <el-col :span="17">
            <el-select v-model="app.data.icon_flag_pos" @change="saveAppData" size="mini" placeholder="Flag position">
              <el-option :value="null" label="Not set" />
              <el-option value="left" label="Left" />
              <el-option value="right" label="Right" />
              <el-option value="false" label="None" />
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="16" type="flex" align="middle" v-permission="['testing']" style="margin-top: 5px">
          <el-col :span="1"><icon-8 name="el-icon-more-outline" width="18" height="18" /></el-col>
          <el-col :span="2"><span>Versions</span></el-col>
          <el-col :span="17">
            <el-button @click="() => rejectAll('testing')" type="warning" size="mini">Set all not tested</el-button>
            <el-button @click="() => rejectAll('rejected')" type="danger" size="mini">Reject all versions</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-alert
            v-if="app.data.do_not_use"
            show-icon
            :closable="false"
            title="Application is currently marked not in use."
            type="warning" />
        </el-row>
        <el-divider content-position="left">Versions <el-link style="margin-left: 10px;" v-permission="'new'" @click="goNewVersion" icon="el-icon-plus">New</el-link></el-divider>

        <el-tabs tab-position="left">
          <el-tab-pane v-for="version in versions" :key="version.id">
            <div slot="label">
              <span class="version">{{version.version}}</span>
              <span class="date">{{$moment(version.created_at).format('DD.MM.YY')}}</span>
            </div>
            <version-page :app="app.data" :version-id="version.id" :disabled="false" :refreshTrigger="forceRefreshVersions" />
          </el-tab-pane>
        </el-tabs>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { appNameTranslated, bundleIdent, setPageTitle } from '@/utils'

import Icon8 from '@/components/icons/Icon8.vue'
import VersionPage from '@/components/VersionPage.vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'AppPage',
  async mounted () {
    try {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/app/' + this.$route.params.bundleId);
      if (!response.data.app) {
        return this.$router.replace({name: 'Index'});
      }
      this.app.data = response.data.app;
      this.app.translate = response.data.translate;
      this.app.completion = response.data.completion;
      this.app.loaded = true;
      this.loading = false;
      setPageTitle(this.app.data.app_name);
    } catch (e) {
      this.loading = false;
      console.error(e);
      if (e.response && e.response.status === 403) {
        this.$message({
          message: "You don't have access to this resource. Try logging out and back in",
          duration: 0,
          type: 'error'
        });
      }
    }
  },
  data () {
    return {
      loading: true,
      app: {
        loaded: false,
        data: {},
        translate: {},
        completion: {},
      },
      buildButton: {
        loading: false,
      },
      editName: null,
      forceRefreshVersions: 0,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales,
      user: state => state.user,
    }),
    categories () {
      return this.app.data.base.base_categories.map(assoc => {
        const baseCategory = assoc.base_category;
        const localCategory = (baseCategory.categories.find(c => c.locale === this.app.data.locale) || {});
        if (localCategory.title === baseCategory.title)
          return baseCategory.title;
        return `${localCategory.title} (${baseCategory.title})`;
      });
    },
    versions: function() {
      return this.app.data.versions.slice().sort((a, b) => b.version_code - a.version_code)
    },
    bigIcon () {
      if (this.app.data.icon_url)
        return this.app.data.icon_url.replace('42x42.', '');
      else
        return null;
    },
    flagCode () {
      let locale = this.locales.find(l => l.code === this.app.data.locale);
      return locale.flag;
    }
  },
  methods: {
    appNameTranslated,

    async saveAppData () {
      try {
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/app', this.app.data);
        if (response.status !== 200 || response.data.status !== 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Updated.'});
        return true;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        return false;
      }
    },
    async deleteApp () {
      try {
        await this.$confirm(`You're about to permanently delete this application (locale: ${this.app.data.locale}). Continue?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      } catch (e) {
        return;
      }
      try {
        await this.$http.delete(process.env.VUE_APP_ROOT_API + '/update/app', {
          data: {bundle_id: this.app.data.bundle_id}
        });
        this.$message({type: 'success', message: 'Delete completed'});
        this.$router.push({name: 'Index'});
      } catch (e) {
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async toggleDoNotUse () {
      if (!this.user.permissions.general.includes('admin') && !this.user.permissions.general.includes('base_edit')) {
        return;
      }

      try {
        let newState = !this.app.data.do_not_use;
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/app', {
          bundle_id: this.app.data.bundle_id,
          do_not_use: newState,
        });
        this.$message({type: 'success', message: 'Updated.'});
        this.app.data.do_not_use = newState;
      } catch (e) {
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      }
    },
    async rejectAll(arg) {
      const infoMessage = this.$message({type: 'info', message: "Updating all versions", duration: 0});
      for (let version of this.app.data.versions) {
        try {
          await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/version', {
            id: version.id,
            tested: arg === 'rejected',
            approved: false,
            web_tested: arg === 'rejected',
            web_approved: false,
          });
          this.forceRefreshVersions++;
          this.$message({type: 'success', message: 'All versions updated', duration: 3000 });
        } catch (e) {
          if (e.status && e.status === 403) {
            this.$notify.error("You don't have access to this function", {duration: 5000});
          } else {
            this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
          }
        }
      }
      infoMessage.close();
    },
    async triggerBuild () {
      this.buildButton.loading = true;
      try {
        await this.$http.post(process.env.VUE_APP_ROOT_API + '/x/build/app', {
          bundle_id: this.app.data.bundle_id
        });
        this.$message({type: 'success', message: 'Build has been queued', duration: 7000 });
      } catch (e) {
        if (e.status && e.status === 403) {
          this.$notify.error("You don't have access to this function", {duration: 5000});
        } else {
          this.$notify.error(e.data?.message || 'Oops. Unknown error occured', {duration: 5000});
        }
      } finally {
        this.buildButton.loading = false;
      }
    },
    goNewVersion () {
      this.$router.push({name: 'New', params: {index: 'version', bundleId: this.app.data.bundle_id}});
    },
  },
  components: {
    Icon8,
    VersionPage,
    CountryFlag
  },
  filters: {
    bundleIdent
  }
}
</script>

<style scoped lang="scss">
.el-header {
  width: 100%;

  span.flag {
    margin: auto -10px -14px -6px;
    transform: scale(.6);
  }
  h2 {
    display: inline-block;
    margin: 0;
    margin-left: 5px;

    .el-checkbox {
      margin-left: 10px;
      transform: translateY(-2px);
    }
  }
  h3 {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .el-col:last-child {
    text-align: right;
  }

  .icon-locked, .icon-delete, .icon-dnu {
    font-size: 24px;
    line-height: 56px;
  }
  .icon-locked, .icon-dnu {
    margin-right: 20px;
  }
  .icon-delete, .icon-dnu {
    cursor: pointer;
  }

  .build-button {
    position: relative;
    top: -5px;
    margin-right: 20px;
  }
}
.el-main {
  margin-top: 25px;
  word-break: break-word;

  .el-tabs {
    .version, .date {
      line-height: 18px;
    }

    .version {
      display: block;
      padding-top: 5px;
    }

    .date {
      display: block;
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>