<template>
  <div>
    <el-row style="margin-bottom: 10px;">
      <el-switch
        style=""
        v-show="appData.length > 0"
        v-model="showDeprecated"
        active-text="Show deprecated" />

      <el-select
        v-model="shownLocales"
        multiple
        filterable
        collapse-tags
        style="float: right;"
        placeholder="Show locales">
        <el-option
          v-for="locale in locales"
          :key="locale.code"
          :label="locale.name"
          :value="locale.code">
          <div style="display: flex;">
            <country-flag :country="locale.flag|lowercase" />
            <span style="margin-left: 10px;">{{ locale.name }}</span>
          </div>
        </el-option>
      </el-select>
    </el-row>

    <div style="height: calc(100vh - 175px)">
      <vxe-table
          highlight-hover-row
          max-height="100%"
          ref="baseTable"
          class="app-list"
          :row-class-name="rowClassName"
          @cell-click="tableCellClick">
        <vxe-table-column title="Icon" width="75" fixed="left">
          <template v-slot="{ row }">
            <img :src="row.icon_url" style="width: 42px; height: 42px;" @error="(e) => e.target.src = '/empty_icon.png'" />
          </template>
        </vxe-table-column>
        <vxe-table-column title="Name" sortable field="app_name" fixed="left" min-width="250" width="auto">
          <template v-slot="{ row }">
            <div class="app-name" style="margin-left: 10px">{{row.app_name}}</div>
            <div class="base-name" style="margin-left: 10px">{{row.l_EN.latestVersion.version}}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column
          label="Bundle"
          min-width="280" width="auto">
          <template v-slot="{ row }">
            <div class="app-name" style="margin-left: 10px;font-size: larger;">{{row.bundle_id|bundleIdent}}</div>
            <div class="base-name" style="margin-left: 10px;font-size: smaller;">{{row.bundle_id|bundleBase}}</div>
          </template>
        </vxe-table-column>
        <vxe-table-column width="75" label="Prio" field="prioritize" sortable>
          <template v-slot="{ row }">
            <icon-8 name="star" width="25" height="25" color="success" v-if="row.prioritize" />
          </template>
        </vxe-table-column>
        <vxe-table-column width="80" label="Auto" field="autobuild_capable" sortable>
          <template v-slot="{ row }">
            <icon-8 name="autobuild" width="25" height="25" color="success" v-if="row.autobuild_capable" />
            <icon-8 name="autobuild_cross" width="25" height="25" color="error" v-else />
          </template>
        </vxe-table-column>

        <vxe-table-column
            width="115"
            sortable
            :sort-method="localeSortFunc(l.code)"
            v-for="l in activeLocales"
            :key="l.code"
            :title="l.name"
            :field="l.code">
          <template v-slot="{ row }">
            <el-popover
              placement="bottom"
              width="100"
              style="float: left;"
              trigger="hover">
              <!-- Translated  -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.translated">
                <el-col :span="5"><icon-8 name="translate" width="24" height="24" color="success" /></el-col>
                <el-col :span="19">Translated</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="5"><icon-8 name="translate" width="24" height="24" color="error" /></el-col>
                <el-col :span="19">Not translated</el-col>
              </el-row>
              <!-- Recorded -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row.audio_requirements && row['l_' + l.code].latestVersion.recorded">
                <el-col :span="5"><icon-8 name="microphone" width="24" height="24" color="success" /></el-col>
                <el-col :span="19">Recorded</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row.audio_requirements && !row['l_' + l.code].latestVersion.recorded">
                <el-col :span="5"><icon-8 name="microphone" width="24" height="24" color="error" /></el-col>
                <el-col :span="19">Not recorded</el-col>
              </el-row>
              <div slot="reference">
                <icon-8 name="complete-in-progress" width="28" height="28" color="warning" v-show="contentComplete(row['l_' + l.code], row) === 'some'" />
                <icon-8 name="cross" width="28" height="28" color="error" v-show="contentComplete(row['l_' + l.code], row) === 'none'" />
              </div>
            </el-popover>
            <icon-8 name="checkmark" width="28" height="28" color="success" style="float: left;" v-show="contentComplete(row['l_' + l.code], row) === 'all'" />

            <el-popover
              placement="bottom"
              :width="175"
              style="float: left;"
              trigger="hover">
              <!-- Tested  -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.tested">
                <el-col :span="4"><icon-8 name="tablet-checked" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Uni Tested</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="tablet-checked" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Uni Not Tested</el-col>
              </el-row>
              <!-- Approved  -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.approved">
                <el-col :span="4"><icon-8 name="tablet-checked" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Uni Not Approved</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="tablet-checked" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Uni Not Approved</el-col>
              </el-row>
              <!-- Synced -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.synced">
                <el-col :span="4"><icon-8 name="tablet-cloud" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Uni Synced</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="tablet-cloud" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Uni Not Synced</el-col>
              </el-row>
              <!-- Tested -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.web_tested">
                <el-col :span="4"><icon-8 name="laptop-checked" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Web Tested</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="laptop-checked" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Web Not Tested</el-col>
              </el-row>
              <!-- Approved -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.web_approved">
                <el-col :span="4"><icon-8 name="laptop-checked" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Web Not Approved</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="laptop-checked" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Web Not Approved</el-col>
              </el-row>
              <!-- Synced -->
              <el-row :gutter="20" style="margin-top: 5px;" v-if="row['l_' + l.code].latestVersion.web_synced">
                <el-col :span="4"><icon-8 name="laptop-cloud" width="24" height="24" color="success" /></el-col>
                <el-col :span="20">Web Synced</el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 5px;" v-else>
                <el-col :span="4"><icon-8 name="laptop-cloud" width="24" height="24" color="error" /></el-col>
                <el-col :span="20">Web Not Synced</el-col>
              </el-row>
              <div slot="reference">
                <icon-8 name="laptop-checked" width="28" height="28" color="warning" style="margin-left: 10px;" v-if="!isFullyApproved(row['l_' + l.code])" />
              </div>
            </el-popover>
            <icon-8 name="cross" width="28" height="28" color="error" style="float: left; margin-left: 10px;" v-if="row['l_' + l.code] == undefined" />
            <icon-8 name="laptop-checked" width="28" height="28" color="success" style="float: left; margin-left: 10px;" v-if="isFullyApproved(row['l_' + l.code])" />

            <div class="version-name">{{row['l_' + l.code].latestVersion.version}}</div>
          </template>
        </vxe-table-column>

        <template v-slot:empty><p>No data</p></template>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import { contentComplete, appNameTranslated, latestVersion, appForLocale, bundleBase, bundleIdent, objectEmpty } from '@/utils'
import Icon8 from '@/components/icons/Icon8.vue'
import CountryFlag from 'vue-country-flag'
import { mapState } from 'vuex'

export default {
  name: 'Index',
  mounted () {
    this.$refs.baseTable.reloadData(this.visibleBases);
  },
  data () {
    return {
      shownLocales: [],
      showDeprecated: false,
    }
  },
  props: {
    appData: Array,
  },
  methods: {
    contentComplete,
    appNameTranslated,
    latestVersion,

    localeSortFunc (locale) {
      return (a, b) => {
        if (objectEmpty(this.latestVersion(a[`l_${locale}`]))) return -1;
        if (objectEmpty(this.latestVersion(b[`l_${locale}`]))) return 1;
        var a_val, b_val;
        a_val = (this.latestVersion(a[`l_${locale}`]).translated && 1)
              + (a.audio_requirements && this.latestVersion(a[`l_${locale}`]).recorded && 1)
        b_val = (this.latestVersion(b[`l_${locale}`]).translated && 1)
              + (b.audio_requirements && this.latestVersion(b[`l_${locale}`]).recorded && 1)
        return a_val < b_val ? -1 : a_val > b_val ? 1 : 0
      }
    },
    filterHandler (value, row) {
      var notNegate = true
      if (value.indexOf('!') === 0) {
        notNegate = false
        value = value.substr(1)
      }
      if (value === "nametranslated") {
        return this.appNameTranslated(row) == notNegate;
      }

      return ((row[value] && true) || (this.latestVersion(row)[value] && true)) == notNegate;
    },
    isFullyApproved (app) {
      if (app == undefined || app.latestVersion == undefined)
        return false;
      return [
        app.latestVersion.web_tested,
        app.latestVersion.tested,
        app.latestVersion.approved,
        app.latestVersion.web_approved,
        app.latestVersion.synced,
        app.latestVersion.web_synced
      ].every(v => !!v);
    },
    rowClassName ({ row }) {
      if (row.deprecated) {
        return 'deprecated';
      }
    },
    tableCellClick ({ row }) {
      this.$router.push({name: 'BasePage', params: {bundleId: row.bundle_id}});
    },
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales,
    }),
    activeLocales () {
      return this.locales.filter(l => this.shownLocales.includes(l.code));
    },
    visibleBases () {
      let bases = [];
      for (let base of this.appData.slice()) {
        if (!this.showDeprecated && base.deprecated)
          continue
        for (let l of this.locales) {
          base['l_' + l.code] = appForLocale(base, l);
          base['l_' + l.code].latestVersion = latestVersion(base['l_' + l.code]);
        }
        bases.push(base);
      }
      return bases;
    }
  },
  watch: {
    'visibleBases' (bases) {
      this.$refs.baseTable.reloadData(bases);
    }
  },
  filters: {
    bundleBase,
    bundleIdent
  },
  components: {
    Icon8,
    CountryFlag,
  }
}
</script>

<style scoped lang="scss">
.app-list {
  tbody td {
    padding: 0;
  }

  .vxe-header--column:not(.col--ellipsis) {
    padding: 5px 0;
  }

  .deprecated {

    * {
      opacity: 0.5;
    }

    div {
      text-decoration: line-through;
    }
  }

  .app-name {
    font-size: larger;
  }
  .base-name {
    font-size: smaller;
  }
  .version-name {
    margin: -10px 0 -10px 25px;
    float: left;
  }
  svg + .version-name {
    margin-top: -5px !important;
  }
  .column-center {
    text-align: center;
  }
}
</style>