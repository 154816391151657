<template>
<el-form ref="licenseForm" :rules="formRules" hide-required-asterisk :model="new_license" @submit.prevent="submitLicense" label-width="120px">
  <el-col :xs="24" :span="12">
    <el-form-item label="Email/User" prop="email">
      <el-input placeholder="Email" v-model="new_license.email" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="License number" prop="number">
      <el-input placeholder="<autogenerate>" v-model="new_license.number" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="Expiration" prop="expiration">
      <el-date-picker
        v-model="new_license.expiration"
        type="date"
        placeholder="Pick a day"
        :picker-options="pickerOptions" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="Name" prop="name">
      <el-input placeholder="Fname Lname" v-model="new_license.name" />
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <el-form-item label="Organization" prop="organization">
      <el-input placeholder="Leap Learning" v-model="new_license.organization" />
    </el-form-item>
  </el-col>

  <el-col :xs="24" :span="12">
    <el-form-item label="City" prop="city">
      <el-input placeholder="Oslo" v-model="new_license.city" />
    </el-form-item>
  </el-col>
  <el-col :xs="24" :span="12">
    <el-form-item label="Country" prop="country">
      <el-input placeholder="Norway" v-model="new_license.country" />
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <el-form-item label="License type" prop="number">
      <el-checkbox-group v-model="new_license.perms">
        <el-checkbox label="apps">Apps</el-checkbox>
        <el-checkbox label="lab">Lab Resources</el-checkbox>
        <el-checkbox label="testing" disabled><i class="el-icon-warning color-warning" />Testing apps</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
  </el-col>

  <el-col :span="12">
    <el-form-item label="Max devices" prop="max_concurrent_sessions">
      <el-input-number size="small" v-model="new_license.max_concurrent_sessions" :min="1" :max="9999" />
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <el-form-item label="With Feide" prop="with_feide">
      <el-switch v-model="new_license.with_feide" active-color="#13ce66" />
    </el-form-item>
  </el-col>
  <template v-if="new_license.with_feide">
    <el-col :span="12">
      <el-form-item label="Realm ID" prop="feide_realm">
        <el-autocomplete
          popper-class="license-realm-autocomplete"
          v-model="new_license.feide_realm"
          :fetch-suggestions="queryFeideRealm"
          placeholder="Type to search..."
          @select="onSelectFeideRealm">
          <template slot-scope="{ item }">
            <div class="name">{{ item.name.nb || item.name.en }}</div>
            <span class="realm">{{ item.realm }}</span>
          </template>
        </el-autocomplete>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="Org ID" prop="feide_org">
        <el-autocomplete
          popper-class="license-realm-autocomplete"
          v-model="new_license.feide_org"
          :fetch-suggestions="queryFeideOrg"
          :debounce="750"
          placeholder="Type to search..."
          @select="onSelectFeideOrg">
          <template slot-scope="{ item }">
            <div class="name">{{ item.navn }}</div>
            <span class="realm">{{ item.organisasjonsnummer }}</span>
          </template>
        </el-autocomplete>
        <div class="feide-hint">{{ feideHint }}</div>
      </el-form-item>
    </el-col>
  </template>

  <el-col :span="24">
    <el-form-item label="Collections" prop="collections" class="collections">
      <draggable
        v-model="new_license.collections"
        v-bind="dragOptions"
        v-show="new_license.collections.length > 0"
        @start="dragging=true">
        <transition-group type="transition" :name="!dragging ? 'flip-list-move' : null" class="license-col-grid">
          <el-tag
            v-for="col in new_license.collections"
            :key="col.id"
            type="info"
            effect="dark"
            @close="onRemoveCollection(col.id)"
            closable>
            <i class="el-icon-d-caret" style="margin-right: 5px" />
            <span>{{col.name}}</span>
            <el-checkbox label="testing" style="margin-left: 15px" :value="col.isTest" @change="ev => onChangeCollectionAccess(col.id, ev)">
              <i class="el-icon-warning color-warning" v-if="col.isTest" />Test
            </el-checkbox>
          </el-tag>
        </transition-group>
      </draggable>

      <el-cascader
        :value="null"
        placeholder="Search/Select app collections"
        size="medium"
        :options="collectionOptions"
        @change="onSelectCollection"
        clearable
        filterable>
        <template slot-scope="{ node, data }">
          <span v-if="!node.isLeaf">{{ data.label }}</span>
          <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
          <div v-if="node.isLeaf">{{ data.label }}</div>
        </template>
      </el-cascader>
    </el-form-item>
  </el-col>

  <el-col :span="24">
    <div style="display: none;">{{insightUserUiFix}}</div>
    <el-form-item label="Insight Users" prop="insight_users">
      <div class="license-insight-users">
        <el-tag
          v-for="user in new_license.insight_users"
          :key="user.id"
          type="info"
          effect="dark"
          @close="onRemoveInsightUser(user.id)"
          closable>
          <a target="_blank" :href="'https://auth.leaplearning.no/admin/user/manage/' + user.id">
            <span v-if="user.firstName">{{user.firstName}} {{user.lastName}}</span>
            <span v-else>{{user.email}}</span>
          </a>
        </el-tag>
      </div>
      <el-autocomplete
        v-model="insightUserSearch"
        :fetch-suggestions="queryInsightUsers"
        style="width: 100%;"
        placeholder="Type to search..."
        popper-class="license-insight-autocomplete"
        @select="onSelectInsightUser"
        @focus="insightUsers = []">
        <template slot-scope="{ item }">
          <div class="name" v-if="item.firstName">{{ item.firstName }} {{ item.lastName }}</div>
          <div class="name" v-else>{{ item.email }}</div>
          <span class="email">{{ item.email }}</span>
        </template>
      </el-autocomplete>
    </el-form-item>
  </el-col>

  <el-form-item class="created-by" style="margin-top: 60px; float: left;" v-if="license">
    <div v-if="license.createdBy">Created by {{license.createdBy.name}}</div>
    <div v-else>Created by unknown</div>
  </el-form-item>

  <el-form-item style="margin-top: 60px; float: right;">
    <el-button v-if="license" type="success" :loading="working" @click="submitLicense">Save</el-button>
    <el-button v-else type="success" :loading="working" @click="submitLicense">Create</el-button>
  </el-form-item>
</el-form>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
  data () {
    const validateFeide = (rule, value, cb) => {
      if (this.new_license.with_feide) {
        if ((value || "").trim() === "")
          return cb(new Error(rule.message));
        if (rule.name === "feide_org" && !/^[\d]{9}$/.test(value))
          return cb(new Error("OrgNr must be just numbers"));
        if (rule.name === "feide_realm" && !/\./.test(value)) {
          return cb(new Error("Realm ID is invalid"));
        }
      }
      return cb();
    };
    return {
      collections: [],
      collectionOptions: [],
      dragging: false,
      feideHint: null,
      insightUsers: [],
      insightUserSearch: "",
      insightUserUiFix: 0,

      formRules: {
        email: [{ required: true, message: 'Email/User is required', trigger: 'submit' }],
        expiration: [{ required: true, message: 'Expiration is required', trigger: 'submit' }],
        perms: [{ required: true, message: 'At least one type is required', trigger: 'submit' }],
        collections: [{ required: true, message: 'At least one collection is required', trigger: 'submit' }],
        feide_realm: [{ name: "feide_realm", validator: validateFeide, message: 'Realm ID is required', trigger: 'submit' }],
        feide_org: [{ name: "feide_org", validator: validateFeide, message: 'OrgNr is required', trigger: 'submit' }],
      },
      working: false,

      new_license: {
        email: null,
        number: null,
        expiration: "",
        name: null,
        organization: null,
        city: null,
        country: null,
        max_concurrent_sessions: 3,
        perms: ["apps"],
        collections: [],
        with_feide: false,
        feide_realm: null,
        feide_org: null,
        insight_users: [],
        removed_insight_user_ids: [],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: 'One month',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          }, {
            text: 'One year',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
              picker.$emit('pick', date);
            }
          }, {
            text: 'School End',
            onClick(picker) {
              const date = new Date();
              if (date.getMonth() > 6) {
                date.setFullYear(date.getFullYear() + 1);
              }
              date.setDate(1);
              date.setMonth(6);
              picker.$emit('pick', date);
            }
          }, {
            text: 'Year End',
            onClick(picker) {
              const date = new Date();
              if (date.getMonth() > 6) {
                date.setFullYear(date.getFullYear() + 1);
              }
              date.setDate(31);
              date.setMonth(11);
              picker.$emit('pick', date);
            }
          }
        ]
      },
    }
  },
  async mounted () {
    try {
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/collections');
      let collectionOptions = [];
      for (let collection of response.data.collections) {
        const locale = this.locales.find(l => l.code === collection.locale);
        if (!locale) {
          continue;
        }
        if (!collectionOptions.find(co => co.value === locale.code)) {
          collectionOptions.push({value: locale.code, label: locale.name});
        }
        const localeOption = collectionOptions.find(co => co.value === locale.code);
        localeOption.children = localeOption.children || [];
        localeOption.children.push({
          label: collection.name,
          value: collection.id
        });
      }
      this.collections = response.data.collections;
      this.collectionOptions = collectionOptions;
      
      if (this.license) {
        this.setExistingLicense(this.license);
      } else {
        // Set default 1yr exp
        const date = new Date();
        date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
        this.new_license.expiration = date;
      }
    } catch (e) {
      console.error(e);
      this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
    }
  },
  props: {
    license: Object
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      locales: state => state.locales
    }),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
      };
    },
  },
  methods: {
    setExistingLicense(license) {
      const hasFeide = license.feideAccess && license.feideAccess.length > 0;
      this.new_license = {
        ...license,
        expiration: new Date(license.expiration * 1000),
        collections: license.appCollections.map(ac => (
          {...ac, isTest: this.licenseTypeToPerms(ac.accessType).includes("testing")}
        )),
        max_concurrent_sessions: license.maxConcurrentSessions,
        perms: this.licenseTypeToPerms(license.type),
        with_feide: hasFeide,
        feide_realm: hasFeide && license.feideAccess[0].realmId,
        feide_org: hasFeide && license.feideAccess[0].orgId.replace(/^NO/, ""),
      };
    },
    licenseTypeToPerms(type) {
      const perms = [];
      if (type & 3) perms.push("apps");
      if (type & 4) perms.push("lab");
      if (type & 8) perms.push("testing");
      return perms;
    },
    onSelectCollection (sel) {
      const [, id] = sel;
      const collection = this.collections.find(c => c.id === id);

      if (collection && !this.new_license.collections.find(c => c.id === id))
        this.new_license.collections.push({...collection, isTest: false});
    },
    onRemoveCollection (id) {
      this.new_license.collections = this.new_license.collections.filter(c => c.id !== id);
    },
    onChangeCollectionAccess(id, value) {
      this.new_license.collections = this.new_license.collections.map(c => {
        if (c.id === id) {
          c.isTest = value;
          return c;
        } else {
          return c;
        }
      });
    },
    onSelectInsightUser (sel) {
      const user = this.insightUsers.find(u => u.id === sel.id);

      if (!this.new_license.insight_users)
        this.new_license.insight_users = [];

      if (user && !this.new_license.insight_users.find(u => u.id === sel.id))
        this.new_license.insight_users.push({...user});

      this.insightUserUiFix += 1;
    },
    onRemoveInsightUser (id) {
      if (!this.new_license.removed_insight_user_ids)
        this.new_license.removed_insight_user_ids = [];

      this.new_license.insight_users = this.new_license.insight_users.filter(u => u.id !== id);
      this.new_license.removed_insight_user_ids.push(id);
      this.insightUserUiFix += 1;
    },
    async fetchInsightUsers() {
      try {
        const response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/insight_users');
        this.insightUsers = response.data.users;
        return response.data.users;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    },
    async queryInsightUsers(queryString, cb) {
      try {
        if (this.insightUsers.length === 0) {
          await this.fetchInsightUsers();
        }
        if (!queryString) {
          cb(this.insightUsers);
          return;
        }
        cb(this.insightUsers.filter(u =>
          (u.firstName + " " + u.lastName).toLowerCase().includes(queryString.toLowerCase()) || u.email.toLowerCase().includes(queryString.toLowerCase())
        ));
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    },
    async queryFeideRealm(queryString, cb) {
      try {
        const response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/feide_orgs', {
          params: {
            query: queryString
          }
        });
        cb(response.data.results);
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    },
    onSelectFeideRealm (realm) {
      this.new_license.feide_realm = realm.realm;
    },
    async queryFeideOrg(queryString, cb) {
      try {
        if (/^\d+$/.test(queryString)) {
          return cb([]);
        }
        const response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/brreg_orgs', {
          params: {
            query: queryString
          }
        });
        cb(response.data.results);
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
    },
    onSelectFeideOrg (org) {
      this.new_license.feide_org = org.organisasjonsnummer;
      this.feideHint = org.navn;
    },
    async submitLicense () {
      let valid = await this.$refs.licenseForm.validate();
      if (!valid) return;
      try {
        this.working = true;
        let updatingParam = "";
        if (this.license) updatingParam = "?id=" + this.license.id;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/new/license' + updatingParam, {...this.new_license});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        if (this.license)
          this.$message({type: 'success', message: 'License updated '});
        else
          this.$message({type: 'success', message: 'License created'});
        this.$router.push({name: "Licenses"});
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.working = false;
    }
  },
  components: {
    draggable,
  }
}
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  justify-content: center;
}
.el-cascader {
  width: 100%
}
.license-col-grid, .license-insight-users {
  display: inline-flex;
  flex-direction: column;

  .el-tag {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    font-size: 14px;

    span, a {
      flex-grow: 1;
    }

    a {
      color: unset;
    }

    &:last-child {
      margin-bottom: 15px;
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.feide-hint {
  font-size: 13px;
  margin-top: -7px;
  margin-left: 5px;
  opacity: 0.7;
}
.created-by {
  opacity: 0.6;
}
</style>
<style lang="scss">
.license-col-grid {
  .el-checkbox .el-checkbox__label {
    color: white;
  }
}
.license-realm-autocomplete, .license-insight-autocomplete {
  width: auto!important;
  display: flex;
  line-height: 15px;
  
  li {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 15px;

    .realm, .email {
      font-size: 12px;
      margin-top: -2px;
    }
  }
}
</style>