<template>
  <div>
    <el-row class="header-row">
      <el-col :offset="22" :span="2">
        <el-button :type="shouldSave ? 'success' : 'info'" :loading="saving" @click="save">Save</el-button>
      </el-col>
    </el-row>
    <div style="height: calc(100vh - 185px); overflow: auto;">
      <draggable
          v-model="gridItems"
          v-bind="dragOptions"
          handle=".app-icon"
          @start="dragging=true"
          @end="onEndMove">
        <transition-group type="transition" :name="!dragging ? 'flip-list-move' : null" class="icon-grid">
          <div v-for="(item, i) in gridItems" :key="item.key" :class="item.base ? 'app-item' : 'section-item'">
            <template v-if="item.base">
              <ribboned-image
                :src="bigIcon(item.base)"
                :title="item.base.bundle_id"
                :width="imageSize"
                :height="imageSize"
                class="app-icon"
                fallback="/empty_icon.png"
                :ribbons="ribbonForIcon(item.base)" />
              <span class="app-name" :title="item.base.bundle_id">{{item.base.app_name}}</span>
              <div class="add-section" @click="addSection(i)"></div>
            </template>
            <template v-if="item.section">
              <el-divider content-position="center" class="section-divider">
                <el-input autofocus
                  :ref="`edit-section-${item.key}`"
                  :value="item.section.title"
                  @input="v => editSectionTitle(i, v)"
                  @blur="editSection(i, false)"
                  @change="editSection(i, false)"
                  size="small"
                  v-if="item.section.editing" />
                <span class="section-title" v-else @click="editSection(i, true)">{{item.section.title || "Section"}}</span>
                <span @click="removeSection(i)" class="remove-section">⨯</span>
              </el-divider>
            </template>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import RibbonedImage from '@/components/RibbonedImage.vue'
import { mapState } from 'vuex';

export default {
  mounted () {
    this.processItems(this.appData, this.categoryData);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  data () {
    return {
      gridItems: [],
      imageSize: 125,
      dragging: false,
      shouldSave: false,
      saving: false,
    }
  },
  props: {
    appData: Array,
    categoryData: Object,
  },
  methods: {
    bigIcon (base) {
      if (base.icon_url)
        return base.icon_url.replace('42x42.', '');
      else
        return '/empty_icon.png';
    },
    ribbonForIcon (base) {
      const curApp = base.children.find(a => a.locale === this.locale.code);
      if (!curApp) {
        return [{id: 1, type: "yellow", text: "EMPTY", tooltip: "App does not exist"}];
      } else if (curApp.do_not_use) {
        return [{id: 1, type: "yellow", text: "NOUSE", tooltip: "App is not in use"}];
      } else if (curApp.versions.length === 0) {
        return [{id: 1, type: "yellow", text: "EMPTY", tooltip: "No versions in app"}];
      }

      const versions = curApp.versions.concat().sort((v1, v2) => v2.version_code - v1.version_code);
      const latestVersion = versions[0];
      const publishedVersion = versions.find(v => v.approved);

      if (latestVersion && publishedVersion && latestVersion.version_code === publishedVersion.version_code)
        return [];

      const ribbons = [];
      if (publishedVersion) {
        ribbons.push({id: 0, type: "success", text: "ACTIVE", tooltip: "Has an active version"});
      }
      if (latestVersion.synced && latestVersion.tested && !latestVersion.approved) {
        ribbons.push({id: 2, type: "error", text: "REJ", tooltip: "Latest version rejected"});
      } else if (latestVersion.synced && !latestVersion.tested && !latestVersion.approved) {
        ribbons.push({id: 3, type: "warning", text: "TEST", tooltip: "Latest version in testing"});
      }
      return ribbons;
    },
    onEndMove (evt) {
      this.dragging = false;
      if (evt.newIndex !== evt.oldIndex) {
        this.shouldSave = true;
      }
    },
    onResize () {
      this.imageSize = (window.innerWidth < 1400) ? 75 : 125;
    },
    processItems (appData, categoryData) {
      const items = [];
      let unusedSections = categoryData.sections.concat();
      // Completely skip deprecated bases, they do not show and does not count towards sorts/indexes
      const bases = appData.filter(b => !b.deprecated);
      for (let i = 0; i < bases.length; i++) {
        const base = bases[i];
        const section = unusedSections.find(s => s.index <= i);
        if (section) {
          unusedSections = unusedSections.filter(s => s.id !== section.id);
          items.push({key: `section-${section.id}`, section: {...section, editing: false}});
        }
        items.push({key: base.bundle_id, base});
      }
      this.gridItems = items;
    },
    addSection (index) {
      this.gridItems.splice(index, 0, {key: `section-new-${index}`, section: {title: null, editing: false}});
      this.shouldSave = true;
    },
    editSection (index, editing) {
      const item = this.gridItems[index];
      item.section.editing = editing;
      this.gridItems.splice(index, 1, item);
      if (editing) {
        this.$nextTick(() => {
          this.$refs[`edit-section-${item.key}`][0].focus();
        });
      }
    },
    editSectionTitle (index, value) {
      const item = this.gridItems[index];
      item.section.title = value;
      this.gridItems.splice(index, 1, item);
    },
    removeSection (index) {
      this.gridItems.splice(index, 1);
      this.shouldSave = true;
    },
    async save () {
      try {
        this.saving = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/base_category_sort', {
          base_category_id: this.categoryData.id,
          items: this.gridItems.map(i => ({key: i.key, title: (i.section || {}).title}))
        });
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'Updated'});
        this.shouldSave = false;
      } catch (e) {
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      }
      this.saving = false;
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale
    }),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    'categoryData' () {
      this.processItems(this.appData, this.categoryData);
    }
  },
  components: {
    draggable,
    RibbonedImage,
  }
}
</script>

<style lang="scss" scoped>
.header-row {
  margin-bottom: 20px;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 36px;
  column-gap: 50px;
  row-gap: 30px;
  padding-top: 5px;
  padding-bottom: 5px;

  & {
    @media (max-width: 1400px) {
      grid-auto-rows: 40px;
      row-gap: 5px;
    }
  }

  .section-item {
    grid-column: span 6;

    .section-divider > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .section-title {
      cursor: pointer;
    }

    .remove-section {
      margin-left: 10px;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .app-item {
    position: relative;
    grid-row: span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: move;

    &.ghost {
      opacity: 0.6;
      background: rgba(63, 63, 63, 0.3);
      border-radius: 6px;
    }

    .app-icon {
      width: 125px;
      height: 125px;

      & {
        @media (max-width: 1400px) {
          width: 75px;
          height: 75px;
        }
      }
    }

    .app-name {
      text-align: center;

      & {
        @media (max-width: 1400px) {
          font-size: 13px;
        }
      }
    }
    
    .add-section {
      position: absolute;
      left: -4px;
      background: rgb(125, 125, 125);
      width: 1px;
      height: 100%;
      cursor: pointer;
      display: none;

      & {
        @media (max-width: 1570px) {
          left: -20px;
        }
      }

      &:before {
        content: '+';
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        background: white;
        left: -15px;
        width: 24px;
        padding-left: 11px;
      }
    }

    &:hover {
      .add-section {
        display: inline-block;
      }
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>