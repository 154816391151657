<template>
<el-table :data="sortedBugs" max-height="600" style="width: 100%">
  <el-table-column
    label="Bugs"
    width="80">
    <template slot-scope="scope">
      <template v-if="editingStatus && editingStatus.id === scope.row.id">
        <el-select
            ref="statusSelect"
            placeholder="Status"
            automatic-dropdown
            v-model="editingStatus.status"
            @change="statusChange(true)"
            @visible-change="statusChange(!$event)"
            v-if="editingStatus && editingStatus.id === scope.row.id">
          <el-option label="Open" value="open" :disabled="editingStatus === 'open'" />
          <el-option label="InProgress" value="inprogress" :disabled="editingStatus === 'inprogress'" />
          <el-option label="Invalid" value="invalid" :disabled="editingStatus === 'invalid'" />
          <el-option label="Fixed" value="fixed" :disabled="editingStatus === 'fixed'" />
        </el-select>
      </template>
      <template v-else>
        <el-tag effect="dark" type="danger" @click="editStatus(scope.row)" v-if="scope.row.status === 'open'">{{ scope.row.status | capitalize }}</el-tag>
        <el-tag effect="dark" type="success" @click="editStatus(scope.row)" v-if="scope.row.status === 'fixed'">{{ scope.row.status | capitalize }}</el-tag>
        <el-tag effect="dark" type="" @click="editStatus(scope.row)" v-if="scope.row.status === 'inprogress'">{{ scope.row.status | capitalize }}</el-tag>
        <el-tag effect="dark" type="info" @click="editStatus(scope.row)" v-if="scope.row.status === 'invalid'">{{ scope.row.status | capitalize }}</el-tag>
      </template>
    </template>
  </el-table-column>
  <el-table-column>
    <template slot="header" slot-scope="">
      <el-link style="float: right;" v-permission="['testing', 'edit', 'developer']" icon="el-icon-plus" @click="creating.visible = true">New</el-link>
    </template>
    <template slot-scope="scope">
      <span class="bug-poster">{{scope.row.poster.name}} - {{scope.row.timestamp|moment('Do MMM, YYYY')}}</span>
      <span style="float: right;"><el-tag size="small" class="bug-category" :type="catToType(scope.row.category)">{{catToDisplay(scope.row.category)}}</el-tag></span>
      <template v-if="editing && editing.id === scope.row.id">
        <el-input type="textarea" autosize placeholder="Please input" v-model="editing.text" />
        <el-link class="bug-save" icon="el-icon-check" @click="save">Save</el-link>
      </template>
      <template v-else>
        <p :class="'bug-text status-' + scope.row.status" v-linkify:options="linkifyOpts">{{scope.row.text}}</p>
        <el-link class="bug-edit" icon="el-icon-edit"
            @click="editing = scope.row" v-if="['open', 'inprogress'].includes(scope.row.status) && scope.row.poster.id == user.id">
          Edit
        </el-link>
      </template>
    </template>
  </el-table-column>

  <el-dialog
      width="35%"
      title="New Bug"
      :close-on-click-modal="false"
      :visible.sync="creating.visible"
      @opened="$refs.newInput.focus()"
      append-to-body>

      <el-input
        type="textarea"
        :autosize="{minRows: 3}"
        placeholder="Please input"
        ref="newInput"
        v-model="creating.text" />

      <div style="margin-top: 20px">
        <el-radio-group v-model="creating.category" size="small">
          <el-radio-button label="Critical">
            Critical
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="Crashes or fundamental issues critical to operation of the app">
              <i class="el-icon-question" slot="reference" />
            </el-popover>
          </el-radio-button>
          <el-radio-button label="Important">
            Important
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="Missing content like images or sound, and game-specific spelling bugs">
              <i class="el-icon-question" slot="reference" />
            </el-popover>
          </el-radio-button>
          <el-radio-button label="Aesthetic">
            Aesthetic
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="Aesthetic issues like wrong colors or splash screen, old images, or spelling mistakes in info/help/settings">
              <i class="el-icon-question" slot="reference" />
            </el-popover>
          </el-radio-button>
          <el-radio-button label="Other">
            Other
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="Any categorization that does not fall under the other options">
              <i class="el-icon-question" slot="reference" />
            </el-popover>
          </el-radio-button>
        </el-radio-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="creating.visible = false; creating.text = null; creating.category = null;">Cancel</el-button>
        <el-button type="primary" :disabled="!creating.text || !creating.category" @click="saveNew">Confirm</el-button>
      </span>
  </el-dialog>

</el-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      editing: null,
      editingStatus: null,
      creating: {
        visible: false,
        text: null,
        category: null,
      },

      linkifyOpts: {
        defaultProtocol: 'https',
        attributes (link) {
          return {
            rel: 'noreferrer',
            title: link
          }
        },
        format (value, type) {
          if (type === 'url') {
            value = value.replace(/^https?:\/\/(www\.)?/, "");
            if (value.length > 30)
            value = value.slice(0, 15) + '…' + value.slice(-15);
          }
          return value;
        }
      }
    }
  },
  methods: {
    catToType (category) {
      if (category === 'other') {
        return 'info';
      } else if (category === 'critical') {
        return 'danger';
      } else if (category === 'important') {
        return 'warning';
      } else if (category === 'aesthetic') {
        return 'success';
      } else {
        return '';
      }
    },
    catToDisplay (category) {
      return category ? `${category[0].toUpperCase()}${category.substring(1)}` : 'Other';
    },
    editStatus (bug) {
      this.editingStatus = bug;
      this.$nextTick(() => this.$refs.statusSelect.focus());
    },
    saveNew () {
      if (!this.creating.text) return;
      this.$emit('new', {text: this.creating.text, category: this.creating.category.toLowerCase()});
      this.creating.visible = false;
    },
    clearNew () {
      this.creating.text = null;
      this.creating.category = null;
    },
    save () {
      this.$emit('save', this.editing);
      this.editing = null;
    },
    statusChange (shouldSave) {
      if (shouldSave) {
        this.$emit('save', this.editingStatus);
        this.editingStatus = null;
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    sortedBugs () {
      return this.bugs.slice().reverse();
    }
  },
  props: {
    bugs: Array
  }
}
</script>

<style scoped lang="scss">
.bug-poster {
  opacity: 0.7;
  font-size: small;
}
.bug-text {
  white-space: pre-wrap;
  margin: 0 auto;
}
.el-table .cell {
  position: relative;
  word-break: break-word;

  .el-tag {
    cursor: pointer;
  }

  &:hover .bug-edit {
    display: block;
  }

  .bug-edit, .bug-save {
    position: absolute;
    top: 5px;
    right: 7px;
  }
  .bug-edit {
    display: none;
  }

  .bug-text.status-fixed,
  .bug-text.status-invalid {
    text-decoration: line-through;
    opacity: 0.5;
  }

  .bug-category.el-tag--success {
    background-color: #fbf8b9;
    border-color: #f9ef02;
    color: #b1a800;
  }
}
</style>