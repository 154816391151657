import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from './store'
import '@/assets/style.scss'
import Element from 'element-ui'
import elementLocale from 'element-ui/lib/locale/lang/en'
import axios from 'axios'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
import VueAxios from 'vue-axios'
import linkify from 'vue-linkify'
import vPermission from '@/directives/vPermission'

import 'xe-utils'
import VXETable from 'vxe-table'
import vxeTableLocale from 'vxe-table/lib/locale/lang/en-US'
import 'vxe-table/lib/style.css'

const getProp = (object, path) => {
  if (path.length === 1) return object[path[0]];
  else if (path.length === 0) throw "Invalid path";
  else {
    if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
    else {
      object[path[0]] = {};
      return getProp(object[path[0]], path.slice(1));
    }
  }
};

// Pages
import Index from '@/pages/Index.vue'
import AppPage from '@/pages/AppPage.vue'
import BasePage from '@/pages/BasePage.vue'
import Login from '@/pages/Login.vue'
import Overview from '@/pages/Overview.vue'
import New from '@/pages/New.vue'
import Development from '@/pages/Development.vue'
import Users from '@/pages/Users.vue'
import Licenses from '@/pages/Licenses.vue'
import LicensePage from '@/pages/LicensePage.vue'

// Config
Vue.config.productionTip = false

Vue.use(Element, { elementLocale })
Vue.use(VueRouter)
Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.use(VueAxios, axios)
Vue.directive('linkify', linkify)
Vue.directive('permission', vPermission)
Vue.use(VXETable, {
  i18n: key => getProp(vxeTableLocale, key.split('.'))
})

axios.defaults.withCredentials = true

// Router
const routes = [
  { path: '/login', name: 'Login', component: Login },
  { path: '/', name: 'Index', component: Index,
    meta: {showModal: false}
  },
  {
    path: '/app/:bundleId', name: 'AppPage',
    components: {
      default: Index,
      modal: AppPage
    },
    meta: {showModal: true}
  },
  { path: '/overview', name: 'Overview', component: Overview,
    meta: {showModal: false}
  },
  {
    path: '/base/:bundleId', name: 'BasePage',
    components: {
      default: Overview,
      modal: BasePage
    },
    meta: {showModal: true}
  },
  { path: '/licenses', name: 'Licenses', component: Licenses, meta: { showModal: false } },
  {
    path: '/licenses/:licenseId', name: 'LicensePage',
    components: {
      default: Licenses,
      modal: LicensePage
    },
    meta: { showModal: true }
  },
  { path: '/new', name: 'New', component: New },
  { path: '/development', name: 'Development', component: Development },
  { path: '/users', name: 'Users', component: Users },
]
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

// App
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
