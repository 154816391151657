<template>
  <el-container class="root">
    <el-main>

      <el-table
        class="user-list"
        empty-text="No users"
        :data="users"
        :row-class-name="rowClassName"
        @row-click="showEdit"
        style="width: 100%">

        <el-table-column
            label="Name"
            sortable
            sort-by="name"
            prop="name" />
        <el-table-column
            label="Email"
            prop="username" />
        <el-table-column
            label="ID"
            prop="user_id" />
        <el-table-column label="Permissions">
          <template slot-scope="scope">
            <div>{{(scope.row.groups || "").replaceAll(",", ", ")}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="">
          <template slot-scope="scope">
            <el-button size="mini" type="info" @click="showEdit(scope.row)">Edit</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- Loading indicator -->
      <span class="data-loading-indicator" v-show="usersLoading" v-loading="usersLoading"></span>


      <el-dialog
        :visible.sync="editUser.visible"
        append-to-body
        width="45%">
        <span slot="title" class="el-dialog__title">
          Edit user {{editUser.user.username}}
        </span>
        <el-form :rules="editUser.rules" :model="editUser.user" hide-required-asterisk>
          <el-form-item prop="groups" label="Permission string">
            <el-input v-model="editUser.user.groups" />
          </el-form-item>
        </el-form>

        <el-alert
          title="Possible entries:"
          type="info"
          show-icon>
          <div class="help-message">
            <div><b>users</b> - User is part of users group</div>
            <div><b>admin</b> - User is part of admin group, has unrestricted access</div>
            <div style="margin-bottom: 10px">--- <b>NOTE:</b> One of the two above must be present ---</div>
            <div><b>locale:en:translate</b> - User can toggle translations for EN language (deprecated, translation are in TT)</div>
            <div><b>locale:en:testing</b> - User can toggle Approved/Rejected for app versions for EN language</div>
            <div><b>locale:en:new</b> - User can create new versions or apps (not bases) for EN language (rarely used)</div>
            <div><b>any:testing</b> - User can toggle Approved/Rejected for app version for any language</div>
            <div><b>developer</b> - User has access to common developer controls, should rarely be used</div>
            <div><b>licenses</b> - User has access to edit and create new licenses</div>
            <div><b>overview</b> - User has access to the Bases page</div>
            <div><b>base:edit</b> - User can edit Base app settings or create new Bases</div>
            <div style="margin-top: 10px">Entries should be separated by comma, no space</div>
          </div>
        </el-alert>

        <span slot="footer" class="dialog-footer">
          <el-button @click="editUser.visible = false">Cancel</el-button>
          <el-button type="success" :loading="editUser.loading" @click="saveUser">Confirm</el-button>
        </span>
      </el-dialog>

    </el-main>
  </el-container>
</template>

<script>
import { setPageTitle } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      users: [],
      usersLoading: true,
      editUser: {
        visible: false,
        user: { groups: "", username: "" },
        loading: false,
        rules: {
          groups: [
            { name: "groups", validator: (rule, value, cb) => {
              if (value.match(/\s/g))
                cb(new Error("No whitespace allowed"));
              else if (value.match(/locale/g)) {
                const m = value.match(/locale:.+?:/g);
                if (!m) cb(new Error("Locale permission is malformed"));
                for (let group of m) {
                  const pl = group.match(/locale:([a-z-]+):/);
                  if (!pl) {
                    cb(new Error("Locale permission is malformed"));
                    return;
                  } else if (!this.locales.find(l => l.code.toLowerCase() === pl[1])) {
                    cb(new Error("Locale code is not a valid locale"));
                    return;
                  }
                }
                cb();
              } else {
                cb();
              }
            }, trigger: 'blur' }
          ],
        }
      },
    }
  },
  mounted () {
    if (!this.user) {
      return this.$router.replace('/login');
    }
    if (!this.user.permissions.general.includes('admin')) {
      return this.$router.replace({name: 'Index'});
    }
    setPageTitle('Users');
    this.loadUsers();
  },
  methods: {
    async loadUsers () {
      this.usersLoading = true;
      let response = await this.$http.get(process.env.VUE_APP_ROOT_API + '/x/users');
      this.users = response.data.users;
      this.usersLoading = false;
    },
    rowClassName ({ row }) {
      if (!row.groups || row.groups === "") {
        return 'deprecated';
      }
    },
    showEdit(row) {
      if (row.username === "System") return;
      this.editUser.user = row;
      this.editUser.visible = true;
    },
    async saveUser() {
      try {
        this.editUser.loading = true;
        let response = await this.$http.post(process.env.VUE_APP_ROOT_API + '/update/user', {...this.editUser.user});
        if (response.status !== 200 || response.data.status != 'ok')
          throw Error(response.data.message);
        this.$message({type: 'success', message: 'User updated'});
        this.editUser.visible = false;
      } catch (e) {
        console.error(e);
        this.$notify.error(e.message || 'Oops. Unknown error occured', {duration: 5000});
      } finally {
        this.editUser.loading = false;
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      locale: state => state.locale,
      locales: state => state.locales,
    }),
  },
  components: {

  }
}
</script>
<style lang="scss">
.user-list {
  .deprecated {
    * {
      opacity: 0.5;
    }

    div {
      text-decoration: line-through;
    }
  }
}
.help-message {
  display: flex;
  flex-direction: column;
}
</style>

<style scoped lang="scss">
.root {
  margin-top: 25px;

  .el-main {
    position: relative;
  }

  .user-list {
    tbody td {
      padding: 0;
    }

    .deprecated {
      * {
        opacity: 0.5;
      }

      div {
        text-decoration: line-through;
      }
    }
  }

  .data-loading-indicator {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
  }
}
</style>
