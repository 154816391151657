import { render, staticRenderFns } from "./VersionAttr.vue?vue&type=template&id=966055c0&"
import script from "./VersionAttr.vue?vue&type=script&lang=js&"
export * from "./VersionAttr.vue?vue&type=script&lang=js&"
import style0 from "./VersionAttr.vue?vue&type=style&index=0&id=966055c0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports